import {registerLanguage} from '@p4b/utils-lang';

const plural = (x: number) => (x > 1 || x === 0 || x < -1);

registerLanguage('Deutsch', {
    LOCALE: 'de-DE',
    LOGIN_INSTRUCTIONS: '<h1>Geben Sie Ihre Anmeldeinformationen ein</h1>',
    LOGIN_CANDIDATE_ID_LABEL: 'Benutzername',
    LOGIN_PASSWORD_LABEL: 'Passwort',
    LOGIN_BUTTON: 'Einloggen',
    FREE_SPACE: ({quota, used}) => `${used}MB Speicher verwendet von ${quota}MB verfügbar`,

    CHOOSE_INSTRUCTIONS: ({cid}) => `<p><b>Sie sind als Benutzer ${cid} angemeldet</b></p>Wenn Sie es nicht sind, bitte abmelden und erneut mit Ihrer bereitgestellten Benutzer-ID anmelden`,
    CHOOSE_PROCTORED_EXAMITY: `<h1>Bitte klicken Sie auf „Prüfungsbeaufsichtigung starten“, um auf die EXAMITY-Plattform zuzugreifen</h1><ul><li>Um Ihr Profil zu erstellen</li><li>Um sich mit Ihrer Prüfungsaufsicht zu verbinden und Ihre Prüfung zu beginnen</li></ul>`,
    CHOOSE_PROCTORED_GENERIC: ({proctorName}) => `<h1>Bitte klicken Sie auf \u201cProktorierung starten\u201d, um auf die Plattform ${proctorName} zuzugreifen</h1>`,
    CHOOSE_PROCTORED_SUPPORT: `Wenn Sie Schwierigkeiten haben, sich mit der Prüfungsaufsicht zu verbinden, kontaktieren Sie bitte Ihr Prüfungs-Supportteam`,
    CHOOSE_NO_EXAMS: `<h1>Es sind keine Prüfungen verfügbar, die Sie absolvieren können</h1>`,
    CHOOSE_SELECT_EXAM: `<h1>Bitte wählen Sie die Prüfung aus, die Sie ablegen</h1>`,
    CHOOSE_AUTO_START: `<h1>Bitte warten, Sie werden automatisch verbunden, wenn Ihre Prüfung geöffnet wird</h1>`,
    CHOOSE_AUTO_SUPPORT: `Wenn Sie nach Beginn Ihrer Prüfung nicht verbunden werden, kontaktieren Sie bitte Ihr Prüfungs-Supportteam`,
    CHOOSE_MANUAL_START: `<h1>Geben Sie den Prüfungs-PIN ein, den Sie erhalten haben</h1>`,
    CHOOSE_MANUAL_SUPPORT: `Wenn Sie Schwierigkeiten haben, die Prüfung zu starten, kontaktieren Sie bitte Ihr Prüfungs-Supportteam`,
    CHOOSE_PRESS_START: `<h1>Klicken Sie auf „Gewählte Prüfung starten“, um zu beginnen</h1>`,
    CHOOSE_EXAM_LABEL: 'Prüfung auswählen',
    CHOOSE_MANUAL_PIN_LABEL: 'Prüfungs-PIN',
    CHOOSE_AUTO_PIN_LABEL: 'Offline-PIN',
    CHOOSE_EXAM_BUTTON: 'Gewählte Prüfung starten',
    CHOOSE_LOGOUT_BUTTON: 'Abmelden',
    CHOOSE_PROCTOR_BUTTON: 'Prüfungsaufsicht starten',
    CHOOSE_EXAM_HINT: 'Wählen Sie die Prüfung aus, die Sie absolvieren möchten...',
    CHOOSE_WAITING_TO_START: 'Prüfungen, die auf den Start warten',
    CHOOSE_ALREADY_STARTED: 'Prüfungen, die bereits begonnen haben',
    CHOOSE_OFFLINE_BUTTON: 'Offline-Modus',

    ERROR_NETWORK: 'Netzwerkfehler',
    ERROR_USER_PASS: '<b>Authentifizierung fehlgeschlagen. Es gibt dieses Konto nicht, oder Sie haben ungültige Anmeldeinformationen angegeben.</b>',
    ERROR_CONNECTION_TIMEOUT: 'Verbindungszeitüberschreitung',
    ERROR_HTTP: ({code}) => `Serverfehler ${code ? ` (HTTP ${code})` : ''}`,
    ERROR_PROXY: 'Server beschäftigt, bitte versuchen Sie es erneut.',
    ERROR_UNKNOWN: ({message}) => `Fehler ${message ? ` [${message}]` : ''}`,
    ERROR_STORAGE: "Sie haben keinen Speicherplatz mehr. Bitte schaffen Sie mehr Platz, oder wechseln Sie zu einem anderen Gerät.",
    ERROR_PIN: '<b>Falscher PIN oder falsche Prüfung ausgewählt<b>',
    ERROR_CANDIDATE_NOT_FOUND: 'Benutzer-ID falsch eingegeben oder nicht für diese Prüfung registriert.',
    ERROR_RESEND: ({err}) => `<b>${err}</b><p>Kontakt mit dem Server nicht möglich. Sie können die Prüfung offline fortsetzen und die Antworten werden lokal auf diesem Gerät gespeichert, müssen aber vor Beendigung der Prüfung an den Server gesendet werden. Sie können keine Antworten sehen, die zuvor von einem anderen Gerät gesendet wurden. Wenn das Netzwerk wiederhergestellt wird, werden alle Antworten automatisch auf den Server gespeichert.</p>`,
    ERROR_DOWNLOAD: 'Prüfung kann nicht heruntergeladen werden',

    DOWNLOAD_TITLE: ({downloadCount}) => `Herunterladen von ${downloadCount} Prüfung${plural(downloadCount) ? 'en' : ''}, bitte warten...`,
    DOWNLOAD_DESCRIPTION: ({freeSpace}) => `<p>Das Herunterladen kann je nach Netzwerkverbindung mehrere Minuten dauern</p>${freeSpace}`,
    DOWNLOAD_COUNT_EXCEEDED: ({examTitle}) => `Downloadanzahl überschritten: ${examTitle}`,

    PREPARING_TITLE: 'Prüfungsvorbereitung, bitte warten...',
    PREPARING_DESCRIPTION: ({freeSpace}) => `<p>Die Vorbereitung kann je nach den Spezifikationen Ihres Computers mehrere Minuten dauern<p>${freeSpace}`,

    CONTROL_OVERVIEW: 'ÜBERSICHT',
    CONTROL_LANGUAGE: 'SPRACHE',
    CONTROL_FINISH: 'BEENDEN',
    CONTROL_CALCULATOR: 'RECHNER',
    CONTROL_COLOUR: 'FARBE',
    CONTROL_VERSION: 'VERSION',
    CONTROL_USERID: 'BENUTZER-ID',
    CONTROL_PREVIOUS: 'VORHERIGE',
    CONTROL_NEXT: 'NÄCHSTE',
    CONTROL_PREVIOUS_CASE: 'VORHERIGER FALL',
    CONTROL_NEXT_CASE: 'NÄCHSTER FALL',
    CONTROL_PREVIOUS_CANDIDATE: 'VORHERIGER KANDIDAT',
    CONTROL_NEXT_CANDIDATE: 'NÄCHSTER KANDIDAT',
    CONTROL_PREVIOUS_STATION: 'VORHERIGE STATION',
    CONTROL_NEXT_STATION: 'NÄCHSTE STATION',
    CONTROL_NOTES: 'NOTIZEN',
    CONTROL_CONNECT: 'VERBINDEN',
    CONTROL_CONNECTING: 'VERBINDEN',
    CONTROL_DISCONNECT: 'TRENNEN',
    CONTROL_DISCONNECTING: 'TRENNEN',
    CONTROL_ID_VALIDATE: ({id}) => `VALIDIEREN ${id}`,
    CONTROL_ID_INVALIDATE: ({id}) => `INVALIDIEREN ${id}`,
    CONTROL_MUTE: 'STUMM',
    CONTROL_UNMUTE: 'STUMMSCHALTUNG AUFHEBEN',
    CONTROL_CAMERA: 'KAMERA',
    CONTROL_SPEAKERS: 'LAUTSPRECHER',
    CONTROL_MICROPHONE: 'MIKROFON',
    CONTROL_HIGHLIGHT: '(DE)MARKIEREN',

    ID_CHECK_INVALIDATE_CONFIRM: 'Sind Sie sicher, dass Sie diesen Ausweis ungültig machen möchten?',

    TITLE_ROUND: 'Runde',
    TITLE_STATION: 'Station',
    TITLE_CIRCUIT: 'Kreislauf',
    TITLE_CASE: ({n, m}) => `Fall&nbsp;<b>${n}</b>&nbsp;von&nbsp;<b>${m}</b>`,
    TITLE_CONNECT_TO: ({factor}) => `verbinden&nbsp;mit&nbsp;${factor}`,

    NOTIFICATION_CONNECT: '<center>Klicken Sie oben auf den <b>VERBINDEN</b>-Button, um zu starten.</center>',
    NO_CANDIDATE: 'Kein Kandidat für diese Runde.',
    REST_STATION: 'Dies ist eine Ruhestation.',

    MEETING_POOR_CONNECTION: 'Schlechte Verbindung: Es können Probleme mit der Audio- und Videoqualität auftreten.',
    MEETING_STATUS_ERROR: ({err}) => `Meeting-Exit-Status: ${err}`,

    TIMER_REMAINING: 'VERBLEIBENDE ZEIT',
    TIMER_ELAPSED: 'VERSTRICHENE ZEIT',
    TIMER_ROUND_REMAINING: 'RUNDE VERBLEIBEND',

    OVERVIEW_TITLE: 'Übersicht',
    OVERVIEW_NO_CANDIDATE: 'Kein Kandidat',
    RESOURCE_RESTRICTED_SHOW: 'Anzeigen',
    RESOURCE_RESTRICTED_HIDE: 'Verbergen',

    ANSWER_OPTIONAL: 'optional',
    ANSWER_REQUIRED: 'erforderlich',
    ANSWER_TITLE: 'Antwort',
    ANSWER_FLAG_TIP: 'Diese Frage markieren',
    SBA_ANSWER_TIP: 'Diese Antwort auswählen',
    SBA_ELIMINATE_TIP: 'Diese Antwort ausschließen',
    NOT_ANSWERED: 'Nicht beantwortet',

    PAUSED_TITLE: 'Prüfung pausiert',
    PAUSED_DESCRIPTION: '<p>Die Prüfung wurde von einem Administrator <strong>pausiert</strong>.</p>',

    STOPPED_TITLE: 'Prüfung beendet',
    STOPPED_CLEANUP: '<p>Aufräumen, bitte warten...<p>',

    MARKSHEET_INVALID: ({invalid}) => `Die folgenden obligatorischen Kriterien benötigen eine Antwort:<br><br><b>${invalid}</b><br><br><div style="text-align:left"><b>OK</b> - um dieses Bewertungsblatt zu verlassen.<br><b>ABBRECHEN</b> - um auf diesem Bewertungsblatt zu bleiben und zu sehen, welche Kriterien fehlen.</div>`,

    FINISH_TITLE: 'Bereit zum Beenden?',
    FINISH_DESCRIPTION: 'Klicken Sie auf „Prüfung einreichen“, bevor Sie den Tab oder das Fenster schließen',
    FINISH_ANSWERED: 'Sie haben alle Fragen beantwortet',
    FINISH_UNANSWERED: ({totalUnanswered}) => `Sie haben ${totalUnanswered} unbeantwortete Frage${plural(totalUnanswered) ? 'n' : ''}`,
    FINISH_MANDATORY_UNANSWERED: ({totalUnanswered, mandatoryUnanswered}) => `Sie haben ${totalUnanswered} unbeantwortete Frage${plural(totalUnanswered) ? 'n' : ''} (${mandatoryUnanswered} obligatorisch)`,
    FINISH_SUBMITTED: '<p><b>Alle Ihre Antworten wurden an den Server gesendet.</b></p>',
    FINISH_UNSUBMITTED: ({unsubmitted}) => `<p>Sie haben <strong>${unsubmitted}</strong> Antwort${plural(unsubmitted) ? 'en' : ''}, die ${plural(unsubmitted) ? 'nicht' : 'nicht'} an den Server gesendet wurden.</p><p>Es ist sicher, die Prüfung fortzusetzen, da Antworten auf Ihrem Gerät gespeichert werden.</p>`,
    FINISH_CONTINUE: 'Prüfung fortsetzen',
    FINISH_NOW: 'Prüfung einreichen',

    INFO_TITLE: 'Anweisungen',
    WARN_TITLE: 'Warnung',
    ALERT_TITLE: 'Etwas ist schiefgelaufen',
    ALERT_OK: 'OK',
    ALERT_CANCEL: 'ABBRECHEN',
    ALERT_RETRY: 'ERNEUT VERSUCHEN',
    ALERT_QUIT: 'BEENDEN',
    ALERT_IGNORE: "ABBRECHEN",

    MEDIA_DEVICES_NOT_SUPPORTED: 'Dieser Browser unterstützt keine Kameras und Mikrofone',
    CAMERA_ERROR: 'Fehler beim Zugriff auf die Kamera',
    CAMERA_INSTRUCTIONS: ({err}) => `<p>[${err}]</p><ul><li>Stellen Sie sicher, dass Ihr Gerät eine eingebaute Kamera hat oder eine Kamera angeschlossen ist</li><li>Sie müssen möglicherweise den Kamerazugriff für <b>risr/</b>assess in den Datenschutz- und Sicherheitseinstellungen Ihres Browsers aktivieren</li><li>Sie müssen möglicherweise den Kamerazugriff für Ihren Browser in den Datenschutz- und Sicherheitseinstellungen Ihres Geräts aktivieren</li></ul>`,
    MICROPHONE_ERROR: "Fehler beim Zugriff auf das Mikrofon",
    MICROPHONE_INSTRUCTIONS: ({err}) => `<p>[${err}]</p><ul><li>Stellen Sie sicher, dass Ihr Gerät ein eingebautes Mikrofon hat oder ein Mikrofon angeschlossen ist</li><li>Sie müssen möglicherweise den Mikrofonzugriff für <b>risr/</b>assess in den Datenschutz- und Sicherheitseinstellungen Ihres Browsers aktivieren</li><li>Sie müssen möglicherweise den Mikrofonzugriff für Ihren Browser in den Datenschutz- und Sicherheitseinstellungen Ihres Geräts aktivieren</li></ul>`,
    NO_CAMERAS: 'Keine Kameras gefunden',
    NO_MICROPHONES: 'Keine Mikrofone gefunden',
    SCREEN_SHARE_PERMISSION: '<p>Für diese Prüfung müssen Sie <i>erlauben</i>, dass Ihr Bildschirm mit dem Prüfer geteilt wird.</p><ul><li>Sie müssen den gesamten Bildschirm teilen, wählen Sie daher bei den Optionen "ganzer Bildschirm", "gesamter Bildschirm" oder Ähnliches.</li><li>Sie müssen möglicherweise "Bildschirmfreigabe" oder "Bildschirmaufzeichnung" für <b>risr/</b>assess in den Datenschutz- und Sicherheitseinstellungen Ihres Browsers erlauben</li><li>Sie müssen möglicherweise "Bildschirmfreigabe" oder "Bildschirmaufzeichnung" für Ihren Browser in den Datenschutz- und Sicherheitseinstellungen Ihres Geräts erlauben</li></ul>',
    SCREEN_SHARE_FAILED: '<b>Bildschirmfreigabe ist derzeit <i>blockiert</i>.</b>',

    VIEWER_RESET: 'ANSICHT ZURÜCKSETZEN',
    VIEWER_SELECT: 'KLICKAKTION WÄHLEN',
    VIEWER_PREV: 'VORHERIGE SEITE',
    VIEWER_NEXT: 'NÄCHSTE SEITE',
    VIEWER_FULLSCREEN: 'VOLLBILD UMSCHALTEN',
    VIEWER_CLOSE: 'BETRACHTER SCHLIESSEN',
    VIEWER_BROADCAST: 'ÄNDERUNGEN ÜBERTRAGEN',
    VIEWER_UNDO: 'RÜCKGÄNGIG MACHEN',
    VIEWER_REDO: 'WIEDERHOLEN',

    LOGOUT_MESSAGE: '<p>Sie sind derzeit abgemeldet, klicken Sie unten, um sich anzumelden.</p>',
    LOGIN_AGAIN: 'ANMELDEN',
    P4B_BACKGROUNDED: 'Dieser Tab wurde unterbrochen, weil ein anderer <b>risr/</b>assess-Prüfungs-Tab aktiv war. Klicken Sie auf OK, um fortzufahren.',
    NEWER_VERSION_AVAILABLE: ({version, latest}) => `<p>Eine neuere Version von <b>risr/</b>assess ist verfügbar</p><p>installiert: ${version}<br/>verfügbar: ${latest}</p>`,
});