import {registerLanguage} from '@p4b/utils-lang';

const plural = (x: number) => (x > 1 || x === 0 || x < -1);

registerLanguage('Italiano', {
    LOCALE: 'it-IT',
    LOGIN_INSTRUCTIONS: '<h1>Inserisci le tue credenziali di accesso</h1>',
    LOGIN_CANDIDATE_ID_LABEL: 'Nome utente',
    LOGIN_PASSWORD_LABEL: 'Password',
    LOGIN_BUTTON: 'Accedi',
    FREE_SPACE: ({quota, used}) => `${used}MB di spazio utilizzato su ${quota}MB disponibili`,

    CHOOSE_INSTRUCTIONS: ({cid}) => `<p><b>Sei connesso come utente ${cid}</b></p>Se non sei tu, per favore esci e accedi nuovamente con l'ID utente che ti è stato fornito`,
    CHOOSE_PROCTORED_EXAMITY: `<h1>Clicca su \u201cInizia proctoring\u201d per accedere alla piattaforma EXAMITY</h1><ul><li>Per creare il tuo profilo</li><li>Per connetterti al tuo supervisore e iniziare il tuo esame</li></ul>`,
    CHOOSE_PROCTORED_GENERIC: (proctorName) => `<h1>Si prega di cliccare su \u201cInizia&nbsp;il&nbsp;monitoraggio\u201d per accedere alla piattaforma ${proctorName}</h1>`,
    CHOOSE_PROCTORED_SUPPORT: `Se hai difficoltà a connetterti con il supervisore, ti preghiamo di contattare il team di supporto dell'esame`,
    CHOOSE_NO_EXAMS: `<h1>Non ci sono esami disponibili per te</h1>`,
    CHOOSE_SELECT_EXAM: `<h1>Seleziona l'esame che stai per sostenere</h1>`,
    CHOOSE_AUTO_START: `<h1>Attendere, verrai connesso automaticamente all'apertura dell'esame</h1>`,
    CHOOSE_AUTO_SUPPORT: `Se non vieni connesso dopo l'orario di inizio dell'esame, ti preghiamo di contattare il team di supporto dell'esame`,
    CHOOSE_MANUAL_START: `<h1>Inserisci il PIN dell'esame che ti è stato fornito</h1>`,
    CHOOSE_MANUAL_SUPPORT: `Se hai difficoltà ad avviare l'esame, ti preghiamo di contattare il team di supporto dell'esame`,
    CHOOSE_PRESS_START: `<h1>Clicca su \u201cSostieni l'esame scelto\u201d per iniziare</h1>`,
    CHOOSE_EXAM_LABEL: 'Scegli esame',
    CHOOSE_MANUAL_PIN_LABEL: 'PIN dell’esame',
    CHOOSE_AUTO_PIN_LABEL: 'PIN offline',
    CHOOSE_EXAM_BUTTON: 'Sostieni l’esame scelto',
    CHOOSE_LOGOUT_BUTTON: 'Esci',
    CHOOSE_PROCTOR_BUTTON: 'Inizia proctoring',
    CHOOSE_EXAM_HINT: 'Scegli l’esame da sostenere...',
    CHOOSE_WAITING_TO_START: 'Esami in attesa di inizio',
    CHOOSE_ALREADY_STARTED: 'Esami già iniziati',
    CHOOSE_OFFLINE_BUTTON: 'Modalità offline',

    ERROR_NETWORK: 'Errore di rete',
    ERROR_USER_PASS: '<b>Autenticazione fallita. Non esiste tale account o hai inserito credenziali non valide.</b>',
    ERROR_CONNECTION_TIMEOUT: 'Timeout della connessione',
    ERROR_HTTP: ({code}) => `Errore del server ${code ? ` (HTTP ${code})` : ''}`,
    ERROR_PROXY: 'Server occupato, riprova.',
    ERROR_UNKNOWN: ({message}) => `Errore ${message ? ` [${message}]` : ''}`,
    ERROR_STORAGE: "Hai esaurito lo spazio di archiviazione. Ti preghiamo di rendere disponibile più spazio o di spostarti su un dispositivo diverso.",
    ERROR_PIN: '<b>PIN errato o esame sbagliato scelto<b>',
    ERROR_CANDIDATE_NOT_FOUND: 'ID utente inserito in modo errato o non registrato per questo esame.',
    ERROR_RESEND: ({err}) => `<b>${err}</b><p>Impossibile contattare il server. Puoi continuare l'esame offline e le risposte verranno memorizzate localmente su questo dispositivo, ma dovranno essere inviate al server prima di poter completare l'esame. Non sarà possibile visualizzare le risposte precedentemente inviate da un dispositivo diverso. Se la rete si riprende, tutte le risposte verranno salvate automaticamente sul server.</p>`,
    ERROR_DOWNLOAD: 'Impossibile scaricare l’esame',

    DOWNLOAD_TITLE: ({downloadCount}) => `Scaricamento di ${downloadCount} esame${plural(downloadCount) ? 'i' : ''}, attendere prego...`,
    DOWNLOAD_DESCRIPTION: ({freeSpace}) => `<p>Il download può richiedere diversi minuti a seconda della connettività di rete</p>${freeSpace}`,
    DOWNLOAD_COUNT_EXCEEDED: ({examTitle}) => `Limite di download superato: ${examTitle}`,

    PREPARING_TITLE: 'Preparazione dell’esame, attendere prego...',
    PREPARING_DESCRIPTION: ({freeSpace}) => `<p>La preparazione può richiedere diversi minuti a seconda delle specifiche del computer<p>${freeSpace}`,

    CONTROL_OVERVIEW: 'PANORAMICA',
    CONTROL_LANGUAGE: 'LINGUA',
    CONTROL_FINISH: 'TERMINA',
    CONTROL_CALCULATOR: 'CALCOLATRICE',
    CONTROL_COLOUR: 'COLORE',
    CONTROL_VERSION: 'VERSIONE',
    CONTROL_USERID: 'ID UTENTE',
    CONTROL_PREVIOUS: 'PRECEDENTE',
    CONTROL_NEXT: 'SUCCESSIVO',
    CONTROL_PREVIOUS_CASE: 'CASO PRECEDENTE',
    CONTROL_NEXT_CASE: 'CASO SUCCESSIVO',
    CONTROL_PREVIOUS_CANDIDATE: 'CANDIDATO PRECEDENTE',
    CONTROL_NEXT_CANDIDATE: 'CANDIDATO SUCCESSIVO',
    CONTROL_PREVIOUS_STATION: 'STAZIONE PRECEDENTE',
    CONTROL_NEXT_STATION: 'STAZIONE SUCCESSIVA',
    CONTROL_NOTES: 'NOTE',
    CONTROL_CONNECT: 'CONNETTI',
    CONTROL_CONNECTING: 'CONNESSIONE IN CORSO',
    CONTROL_DISCONNECT: 'DISCONNETTI',
    CONTROL_DISCONNECTING: 'DISCONNESSIONE IN CORSO',
    CONTROL_ID_VALIDATE: ({id}) => `VALIDA ${id}`,
    CONTROL_ID_INVALIDATE: ({id}) => `INVALIDA ${id}`,
    CONTROL_MUTE: 'MUTO',
    CONTROL_UNMUTE: 'RIPRISTINA AUDIO',
    CONTROL_CAMERA: 'FOTOCAMERA',
    CONTROL_SPEAKERS: 'ALTOPARLANTI',
    CONTROL_MICROPHONE: 'MICROFONO',
    CONTROL_HIGHLIGHT: '(DIS)EVIDENZIA',

    ID_CHECK_INVALIDATE_CONFIRM: 'Sei sicuro di voler invalidare questa ID?',

    TITLE_ROUND: 'round',
    TITLE_STATION: 'stazione',
    TITLE_CIRCUIT: 'circuito',
    TITLE_CASE: ({n, m}) => `caso&nbsp;<b>${n}</b>&nbsp;di&nbsp;<b>${m}</b>`,
    TITLE_CONNECT_TO: ({factor}) => `collegati&nbsp;a&nbsp;${factor}`,

    NOTIFICATION_CONNECT: '<center>Clicca sul bottone <b>CONNETTI</b> sopra per iniziare.</center>',
    NO_CANDIDATE: 'Nessun candidato per questo turno.',
    REST_STATION: 'Questa è una stazione di riposo.',

    MEETING_POOR_CONNECTION: 'Connessione scadente: potresti riscontrare problemi di qualità audio e video.',
    MEETING_STATUS_ERROR: ({err}) => `Stato di uscita della riunione: ${err}`,

    TIMER_REMAINING: 'TEMPO RIMANENTE',
    TIMER_ELAPSED: 'TEMPO TRASCORSO',
    TIMER_ROUND_REMAINING: 'ROUND RIMANENTE',

    OVERVIEW_TITLE: 'Panoramica',
    OVERVIEW_NO_CANDIDATE: 'Nessun Candidato',
    RESOURCE_RESTRICTED_SHOW: 'Mostra',
    RESOURCE_RESTRICTED_HIDE: 'Nascondi',

    ANSWER_OPTIONAL: 'opzionale',
    ANSWER_REQUIRED: 'obbligatorio',
    ANSWER_TITLE: 'Risposta',
    ANSWER_FLAG_TIP: 'Segnala questa domanda',
    SBA_ANSWER_TIP: 'Seleziona questa risposta',
    SBA_ELIMINATE_TIP: 'Elimina questa risposta',
    NOT_ANSWERED: 'Non Risposto',

    PAUSED_TITLE: 'Esame in pausa',
    PAUSED_DESCRIPTION: '<p>L\'esame è stato <strong>messo in pausa</strong> a distanza da un amministratore.<p>',

    STOPPED_TITLE: 'Esame Terminato',
    STOPPED_CLEANUP: '<p>Pulizia in corso, attendere prego...<p>',

    MARKSHEET_INVALID: ({invalid}) => `I seguenti criteri obbligatori necessitano di una risposta:<br><br><b>${invalid}</b><br><br><div style="text-align:left"><b>OK</b> - per lasciare questo foglio di valutazione.<br><b>CANCELLA</b> - per rimanere su questo foglio di valutazione e vedere quali criteri mancano.</div>`,

    FINISH_TITLE: 'Pronto a terminare?',
    FINISH_DESCRIPTION: 'Clicca su \u201cInvia esame\u201d prima di chiudere la scheda o la finestra',
    FINISH_ANSWERED: 'Hai risposto a tutte le domande',
    FINISH_UNANSWERED: ({totalUnanswered}) => `Hai ${totalUnanswered} domanda${plural(totalUnanswered) ? 'e' : ''} senza risposta`,
    FINISH_MANDATORY_UNANSWERED: ({totalUnanswered, mandatoryUnanswered}) => `Hai ${totalUnanswered} domanda${plural(totalUnanswered) ? 'e' : ''} senza risposta (${mandatoryUnanswered} obbligatorie)`,
    FINISH_SUBMITTED: '<p><b>Tutte le tue risposte sono state inviate al server.</b></p>',
    FINISH_UNSUBMITTED: ({unsubmitted}) => `<p>Hai <strong>${unsubmitted}</strong> risposta${plural(unsubmitted) ? 'e' : ''} che ${plural(unsubmitted) ? 'non sono state' : 'non è stata'} inviate al server.</p><p>È sicuro continuare l'esame poiché le risposte verranno memorizzate sul tuo dispositivo.</p>`,
    FINISH_CONTINUE: 'Continua esame',
    FINISH_NOW: 'Invia esame',

    INFO_TITLE: 'Istruzioni',
    WARN_TITLE: 'Attenzione',
    ALERT_TITLE: 'Qualcosa è andato storto',
    ALERT_OK: 'OK',
    ALERT_CANCEL: 'ANNULLA',
    ALERT_RETRY: 'RIPROVA',
    ALERT_QUIT: 'ESCI',
    ALERT_IGNORE: "IGNORA",

    MEDIA_DEVICES_NOT_SUPPORTED: 'Questo browser non supporta le fotocamere e i microfoni',
    CAMERA_ERROR: 'Errore nell\'accesso alla fotocamera',
    CAMERA_INSTRUCTIONS: ({err}) => `<p>[${err}]</p><ul><li>Assicurati che il tuo dispositivo abbia una fotocamera integrata, o che una fotocamera sia collegata</li><li>Potresti dover abilitare l'accesso alla fotocamera per <b>risr/</b>assess nelle impostazioni di privacy e sicurezza del tuo browser</li><li>Potresti dover abilitare l'accesso alla fotocamera per il tuo browser nelle impostazioni di privacy e sicurezza del tuo dispositivo</li></ul>`,
    MICROPHONE_ERROR: "Errore nell'accesso al microfono",
    MICROPHONE_INSTRUCTIONS: ({err}) => `<p>[${err}]</p><ul><li>Assicurati che il tuo dispositivo abbia un microfono integrato, o che un microfono sia collegato</li><li>Potresti dover abilitare l'accesso al microfono per <b>risr/</b>assess nelle impostazioni di privacy e sicurezza del tuo browser</li><li>Potresti dover abilitare l'accesso al microfono per il tuo browser nelle impostazioni di privacy e sicurezza del tuo dispositivo</li></ul>`,
    NO_CAMERAS: 'Nessuna fotocamera trovata',
    NO_MICROPHONES: 'Nessun microfono trovato',
    SCREEN_SHARE_PERMISSION: '<p>Per questo esame devi <i>permettere</i> la condivisione dello schermo con l\'esaminatore.</p><ul><li>Devi condividere l\'intero schermo, quindi se ti vengono date delle opzioni dovresti selezionare "intero schermo", "schermo intero" o equivalente.</li><li>Potresti dover permettere la "condivisione dello schermo" o la "registrazione dello schermo" per <b>risr/</b>assess nelle impostazioni di privacy e sicurezza del tuo browser</li><li>Potresti dover permettere la "condivisione dello schermo" o la "registrazione dello schermo" per il tuo browser nelle impostazioni di privacy e sicurezza del tuo dispositivo</li></ul>',
    SCREEN_SHARE_FAILED: '<b>La condivisione dello schermo è attualmente <i>bloccata</i>.</b>',

    VIEWER_RESET: 'RESETTA VISUALIZZAZIONE',
    VIEWER_SELECT: 'SELEZIONA AZIONE CLIC',
    VIEWER_PREV: 'PAGINA PRECEDENTE',
    VIEWER_NEXT: 'PAGINA SUCCESSIVA',
    VIEWER_FULLSCREEN: 'ATTIVA/DISATTIVA SCHERMO INTERO',
    VIEWER_CLOSE: 'CHIUDI VISUALIZZATORE',
    VIEWER_BROADCAST: 'TRASMETTI CAMBIAMENTI',
    VIEWER_UNDO: 'ANNULLA CAMBIAMENTI',
    VIEWER_REDO: 'RIPETI CAMBIAMENTI',

    LOGOUT_MESSAGE: '<p>Al momento sei disconnesso, clicca qui sotto per accedere.</p>',
    LOGIN_AGAIN: 'ACCEDI',
    P4B_BACKGROUNDED: 'Questa scheda è stata sospesa perché un\'altra scheda di esame <b>risr/</b>assess era attiva. Clicca OK per riprendere.',
    NEWER_VERSION_AVAILABLE: ({version, latest}) => `<p>È disponibile una versione più recente di <b>risr/</b>assess</p><p>installata: ${version}<br/>disponibile: ${latest}</p>`,
});
