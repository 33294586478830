import { Img, IType } from '@p4b/image-base';
import { mkNode, removeNode } from '@p4b/utils';
import { Thumbnail, ThumbnailObserver } from '@p4b/thumbnail-base';
import { ResourceThumbnail } from '@p4b/thumbnail-image';
import { ResourceVideo } from '@p4b/thumbnail-video';
import { ResourceAudio } from '@p4b/thumbnail-audio';
import { Lightbox } from '@p4b/lightbox';
import { SHARE_MODE } from '@p4b/image-viewer';
import { ComponentDetails } from '@p4b/meeting';
import { alertModal } from './modal-dialog';

export interface ThumbnailContext {
    fullscreenParent?: Element;
    scrollContainer: Element;
    sizeReference: Element;
    resources: {
        getImageBegin: () => Promise<void>;
        getImageFrame: (start: number, end: number) => Promise<ArrayBuffer|undefined>;
        getImageEnd: () => Promise<void>;
    };
    navigation: {
        getNavigating: () => boolean;
        setNavigating: (isNavigating: boolean) => void;
    };
    isRemoteShowHide: boolean;
    disableSharedManipulation: boolean;
    component: ComponentDetails;
}

export class ThumbnailViewer {
    private context: ThumbnailContext;
    private thumbnailPanel: HTMLDivElement;
    private thumbnails: Thumbnail[] = [];
    private lightbox: Lightbox;

    constructor(context: ThumbnailContext, parent: Node, images: Img[], lightbox: Lightbox) {
        this.context = context;
        this.lightbox = lightbox;
        this.thumbnailPanel = mkNode('div', {className: 'thumbs', parent: parent});
        for (let j = 0; j < images.length; ++j) {
            let thumbnail;
            switch (images[j].iType) {
                case IType.Std:
                case IType.Dicom:
                case IType.Tiff:
                case IType.Pdf:
                    thumbnail = new ResourceThumbnail(images[j], this.thumbnailPanel, !context.isRemoteShowHide);
                    break;
                case IType.Video:
                    thumbnail = new ResourceVideo(images[j], this.thumbnailPanel, !context.isRemoteShowHide);
                    break;
                case IType.Audio:
                    thumbnail = new ResourceAudio(images[j], this.thumbnailPanel, !context.isRemoteShowHide);
                    break;
                default:
                    thumbnail = new ResourceThumbnail(images[j], this.thumbnailPanel, !context.isRemoteShowHide);
                    break;
            }
            thumbnail.hide(context.isRemoteShowHide);
            this.thumbnails.push(thumbnail);
        }
    }

    public async loadResources(buffers: (ArrayBuffer|null)[]): Promise<void> {
        for (let j = 0; j < this.thumbnails.length; ++j) {
            const thumbnail = this.thumbnails[j];
            if (thumbnail instanceof ResourceThumbnail) {
                await this.thumbnails[j].load(buffers[j]);
            } else if ((thumbnail instanceof ResourceVideo) || (thumbnail instanceof ResourceAudio)) {
                if (thumbnail.image.frames.length > 0) {
                    const frame = thumbnail.image.frames[0];
                    if (frame.dbOffset != undefined) {
                        this.context.resources.getImageBegin();
                        const data = await this.context.resources.getImageFrame(frame.dbOffset, frame.dbOffset + frame.dataSize);
                        this.context.resources.getImageEnd();
                        if (data) {
                            await this.thumbnails[j].load(data);
                        }
                    }
                }
            }
        }
        this.thumbnailPanel.addEventListener('click', this.handleClick);
    }

    public destroy(): void {
        this.thumbnailPanel.removeEventListener('click', this.handleClick);
        removeNode(this.thumbnailPanel);
        for (const thumbnail of this.thumbnails) {
            thumbnail.destroy();
        }
    }

    public disableShowHide(disable: boolean): void {
        for (const thumbnail of this.thumbnails) {
            thumbnail.disableShowHide(disable);
        }
    }

    public disabled(disable: boolean): void {
        for (const thumbnail of this.thumbnails) {
            thumbnail.disable(disable);
        }
    }

    public getStatus(status: {id: string, released: boolean}[]): void {
        for (const thumbnail of this.thumbnails) {
            status.push({id: thumbnail.getImage().id, released: thumbnail.getStatus()});
        }
    }

    public setStatus(status: {id?: string, released: boolean}): void {
        for (const thumbnail of this.thumbnails) {
            const image = thumbnail.getImage();
            if (status.id === undefined || image.id === status.id) {
                if (this.context.isRemoteShowHide && image.distribution === 'restricted') {
                    thumbnail.hide(!status.released);
                    if (!status.released) {
                        this.lightbox.close(image.id);
                    }
                } else {
                    thumbnail.setStatus(status.released);
                }
                if (status.id !== undefined) {
                    break;
                }
            }
        }
    }

    public addObserver(observer: ThumbnailObserver): void {
        for (const thumbnail of this.thumbnails) {
            thumbnail.addObserver(observer);
        }
    }

    private readonly handleClick = (event: MouseEvent): void => {(async () => {
        if (this.context.navigation.getNavigating()) {
            return;
        }
        for (const thumbnail of this.thumbnails) {
            if (event.target instanceof Node && thumbnail instanceof ResourceThumbnail && thumbnail.contains(event.target) /*thumbnail.contains({x: event.clientX, y: event.clientY})*/ && !thumbnail.isDisabled()) {
                this.context.navigation.setNavigating(true);
                try {
                    const viewer = this.lightbox.open();
                    thumbnail.open(viewer);
                    this.lightbox.updateMode();
                    if (this.context) {
                        if (this.context.disableSharedManipulation) {
                            viewer.setDisableShare(true);
                            viewer.setPrivateOnly(true);
                            viewer.setShareMode(SHARE_MODE.PRIVATE);
                        } else if(thumbnail.isHidden()) {
                            viewer.setDisableShare(false);
                            viewer.setPrivateOnly(true);
                            viewer.setShareMode(SHARE_MODE.PRIVATE);
                        } else {
                            viewer.setDisableShare(false);
                            viewer.setPrivateOnly(false);
                            if (this.context.component === ComponentDetails.ROLE_CANDIDATE) {
                                viewer.setDisablePrivate(true);
                                viewer.setShareMode(SHARE_MODE.BROADCAST);
                            } else {
                                viewer.setDisablePrivate(false);
                                viewer.setShareMode(SHARE_MODE.RECEIVE);
                            }
                        }
                    }
                    await thumbnail.defferedLoad(viewer);
                } finally {
                    this.context.navigation.setNavigating(false);
                }
            }
        }
    })().catch(err => {
        console.error(err);
        alertModal(String(err));
    })};
}
