import { Canvas, createCanvas, createImageData, CanvasRenderingContext2D, ImageData } from 'canvas';
import { Transform } from '@p4b/utils';
import { Img, IType, Renderer, registerRendererType } from '@p4b/image-base';
import * as utif from 'utif';

type Tiff = Img;
//export interface Tiff extends Img {}

export type Type = Tiff;

export function makeTiff(id: string, data: ArrayBuffer[]): Tiff {
    return {
        id,
        iType: IType.Tiff,
        frames: data.map(buf => ({
            data: buf,
            dataSize: buf.byteLength,
        })),
        frameCount: data.length,
    }
}

export function isTiff(x: Img): x is Tiff {
    return x.iType === IType.Tiff;
}

export function tiffDetect(buffer: ArrayBuffer): boolean {
    if (buffer.byteLength < 4) {
        return false;
    }
    const data = new Uint8Array(buffer);
    console.log('IS_TIFF: ',
        data[0].toString(16),
        data[1].toString(16),
        data[2].toString(16),
        data[3].toString(16)
    );
    return (data[0] === 0x49 && data[1] === 0x49 && data[2] === 0x2a && data[3] === 0x00) ||
        (data[0] === 0x4d && data[1] === 0x4d && data[2] === 0x00 && data[3] === 0x2a);
}

export class TiffRenderer implements Renderer {
    public index: number;
    public readonly img: Tiff;
    private canvas?: Canvas;

    public constructor(img: Tiff) {
        this.img = img;
        this.index = 0;
    }

    public async init(): Promise<void> {
        // no-op
    }

    public destroy(): void {
        //this.img.data = [];
    }

    private toCanvas(data: ArrayBuffer): void {
        const ifds = utif.decode(data);
        utif.decodeImage(data, ifds[0]);
        this.img.cols = ifds[0].width;
        this.img.rows = ifds[0].height;
        this.canvas = createCanvas(this.img.cols, this.img.rows);
        const rgba  = utif.toRGBA8(ifds[0]);
        const context = this.canvas.getContext('2d', {alpha: false});
        context.putImageData(createImageData(new Uint8ClampedArray(rgba), this.img.cols, this.img.rows), 0, 0);
    }

    public async render(): Promise<void> {
        /*const data = this.img.data[0];
        if (!data) {
            return Promise.reject('image is not loaded');
        }
        const ifds = utif.decode(data);
        utif.decodeImage(data, ifds[0]);
        this.img.cols = ifds[0].width;
        this.img.rows = ifds[0].height;
        if (!this.canvas) {
            this.canvas = createCanvas(this.img.cols, this.img.rows);
        }
        const rgba  = utif.toRGBA8(ifds[0]);
        const context = this.canvas.getContext('2d', {alpha: false});
        context.putImageData(createImageData(new Uint8ClampedArray(rgba), this.img.cols, this.img.rows), 0, 0);
        return Promise.resolve();*/
    }

    public async renderThumbnail(): Promise<ImageData> {
        const {data} = this.img.frames[this.index];
        if (!data) {
            throw 'image is not loaded';
        }
        const ifds = utif.decode(data);
        utif.decodeImage(data, ifds[0]);
        const cols = ifds[0].width;
        const rows = ifds[0].height;
        this.img.cols = cols;
        this.img.rows = rows;
        const rgba  = utif.toRGBA8(ifds[0]);
        const c = createCanvas(cols, rows);
        const x = c.getContext('2d', { alpha: false });
        if (!x) {
            throw 'hidden CONTEXT is null';
        }
        x.putImageData(createImageData(new Uint8ClampedArray(rgba), cols, rows), 0, 0);
        const canvas = createCanvas(120 * cols / rows, 120);
        const cxt = canvas.getContext('2d', { alpha: false });
        if (!cxt) {
            throw 'visible CONTEXT is null';
        }
        cxt.imageSmoothingEnabled = true;
        cxt.imageSmoothingQuality = 'high';
        cxt.drawImage(c, 0, 0, c.width, c.height, 0, 0, canvas.width, canvas.height);
        return cxt.getImageData(0, 0, canvas.width, canvas.height);
    }

    public async animationFrame(context: CanvasRenderingContext2D, t: Transform): Promise<void> {
        //context.clearRect(0, 0, context.canvas.width, context.canvas.height);
        context.fillStyle = 'black';
        context.fillRect(0, 0, context.canvas.width, context.canvas.height);
        if (this.canvas) {
            context.setTransform(t.s, t.r, -t.r, t.s, t.tx, t.ty);
            context.imageSmoothingEnabled = true;
            context.imageSmoothingQuality = 'high';
            context.drawImage(this.canvas, 0, 0);
            //    Math.floor(context.canvas.width / 2 - this.canvas.width / 2),
            //    Math.floor(context.canvas.height / 2 - this.canvas.height / 2)
            //);
        }
    }

    public async load(
        resources: {
            getImageBegin(): Promise<void>;
            getImageFrame(start: number, end: number): Promise<ArrayBuffer|undefined>;
            getImageEnd(): Promise<void>;
        },
        render: () => Promise<void>,
        progress: (p: number) => void,
    ): Promise<void> {
        progress(100.0);
        await resources.getImageBegin();
        let j = this.index;
        for (let i = 0; i < this.img.frameCount; ++i) {
            j = (j === this.img.frameCount) ? 0 : j;
            const frame = this.img.frames[j];
            if (frame.dbOffset != undefined) {
                frame.data = await resources.getImageFrame(frame.dbOffset, frame.dbOffset + frame.dataSize);
            }
            if (j === this.index && frame.data !== undefined) {
                this.toCanvas(frame.data);
                await render();
            }
            progress(100.0 - 100.0 * (i + 1) / this.img.frameCount);
            ++j;
        }
        await resources.getImageEnd();
    }

    public convexMean(): {mean?: number, stddev?: number} {
        return {};
    }
}

export function isTiffRenderer(renderer: Renderer): renderer is TiffRenderer {
    return isTiff(renderer.img);
}

registerRendererType({
    name: 'TiffRenderer',
    hasMime(mime: string) {
        return mime === 'image/tiff';
    },
    async makeImg(id: string, buffer: ArrayBuffer): Promise<Img> {
        return makeTiff(id, [buffer]);
    },
    isThis(resource: Img) {
        return resource.iType === IType.Tiff;
    },
    makeRenderer(resource: Tiff): Renderer {
        return new TiffRenderer(resource);
    }
});