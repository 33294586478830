import { scrollRangeIntoView, removeNode, removeChildren } from '@p4b/utils';
import { DOMcreateElement, Html } from '@p4b/utils-jsx';
import { IconDefinition, icon } from '@fortawesome/fontawesome-svg-core';

/** Dropdown question textarea UI */
export class Dropdown {
    private isOpen = false;
    private index = -1;
    private highlightIndex = -1;
    private options: string[] = [];
    //private values: number[] = [];

    private name: string;
    private disabled: boolean;
    private selectElement: HTMLDivElement;
    private selectedElement: HTMLDivElement;
    private optionsElement: HTMLDivElement;
    private backgroundElement: HTMLElement|null;
    private menuIcon?: IconDefinition;
    private optionClass?: string;

    private async open(): Promise<void> {
        console.debug('OPEN');
        this.optionsElement.hidden = false;
        this.selectedElement.setAttribute('aria-expanded', 'true');
        this.selectElement.setAttribute('aria-expanded', 'true');
        this.isOpen = true;
        //this.highlight(0);
        this.highlight(this.index);
        for (let i = 0; i < this.optionsElement.children.length; ++i) {
            for (const e of this.optionsElement.children[i].getElementsByClassName(`${this.name}-dropdown-icon`)) {
                e.setAttribute('hidden', String(i !== this.index));
            }
        }
        //this.focus();
        //await yieldMacroTask();
        //this.selectElement.focus();
        this.optionsElement.addEventListener('mousedown', this.optionsDownHandler);
        this.backgroundElement?.addEventListener('mousedown', this.windowCloseHandler);
        this.focus();
    }

    private async close(): Promise<void> {
        console.debug('CLOSE')
        this.optionsElement.removeEventListener('mousedown', this.optionsDownHandler);
        this.backgroundElement?.removeEventListener('mousedown', this.windowCloseHandler);
        if (this.highlightIndex !== this.index) {
            this.selectElement.dispatchEvent(new CustomEvent('dropdown-select', {detail: this.highlightIndex, bubbles: true}));
            removeChildren(this.selectedElement)
            this.selectedElement.appendChild(this.getIconSpan(false));
            this.selectedElement.appendChild(<Html html={this.options[this.highlightIndex]}/>);
            this.index = this.highlightIndex;
        }
        this.optionsElement.hidden = true;
        this.selectedElement.setAttribute('aria-expanded', 'false');
        this.selectElement.setAttribute('aria-expanded', 'false');
        this.isOpen = false;
        //this.selectElement.focus();
    }

    private highlight(index: number) {
        if (this.highlightIndex >= 0) {
            this.optionsElement.children[this.highlightIndex].setAttribute('aria-selected', 'false');
        }
        if (index >= 0) {
            this.optionsElement.children[index].setAttribute('aria-selected', 'true');
        }
        this.highlightIndex = index;
    }

    private getOptionIndex(t: EventTarget|null): number {
        if (t instanceof Node) {
            for (let i = 0; i < this.optionsElement.children.length; ++i) {
                const child = this.optionsElement.children[i];
                if (child.contains(t)) {
                    return i;
                }
            }
        }
        return -1;
    }

    private keyHandler = async (e: KeyboardEvent): Promise<void> => {
        if (e.target instanceof HTMLElement) {
            switch(e.key) {
                case 'Tab':
                    if (this.isOpen) {
                        this.close();
                    }
                    break;
                case 'Enter':
                    e.preventDefault();
                    if (this.isOpen) {
                        this.close();
                    } else {
                        setImmediate(() => this.open());
                    }
                    break;
                case 'Escape':
                    if (this.isOpen) {
                        e.preventDefault();
                        this.highlight(this.index);
                        this.close();
                    }
                    break;
                case 'Up':
                case 'ArrowUp':
                    if (!this.disabled && this.isOpen) { // fix keyboard to keep open
                        e.preventDefault();
                        this.highlight((this.highlightIndex < 0) ? this.optionsElement.children.length - 1 : (this.highlightIndex > 0) ? this.highlightIndex - 1 : this.highlightIndex)
                    }
                    break;
                case 'Down':
                case 'ArrowDown':
                    if (!this.disabled && this.isOpen) {
                        e.preventDefault();
                        this.highlight((this.highlightIndex < 0) ? 0 : (this.highlightIndex + 1 < this.optionsElement.children.length) ? this.highlightIndex + 1 : this.highlightIndex);
                    }
                    break;
            }
        }
    }

    private startX?: number;
    private startY?: number;

    private selectedDownHandler = async (e: MouseEvent): Promise<void> => {
        if (e.button == 0) {
            console.debug('DOWN', this.disabled);
            if (!this.disabled) {
                this.startX = e.clientX;
                this.startY = e.clientY;
                if (this.isOpen) {
                    this.close();
                } else {
                    setImmediate(() => this.open());
                    this.optionsElement.addEventListener('mousemove', this.optionsMoveHandler);
                    this.optionsElement.addEventListener('mouseup', this.optionsUpHandler);
                    this.backgroundElement?.addEventListener('mouseup', this.windowUpHandler);
                }
            }
        }
    }

    private optionsDownHandler = (e: MouseEvent): void => {
        if (!this.disabled) {
            e.preventDefault();
            const i = this.getOptionIndex(e.target);
            if (i >= 0) {
                this.highlight(i);
                this.startX = undefined;
                this.startY = undefined;
            }
            this.optionsElement.addEventListener('mousemove', this.optionsMoveHandler);
            this.optionsElement.addEventListener('mouseup', this.optionsUpHandler);
            this.backgroundElement?.addEventListener('mouseup', this.windowUpHandler);
        }
    }

    private optionsMoveHandler = (e: MouseEvent): void => {
        if (!this.disabled && (this.startX === undefined || this.startY === undefined || Math.abs(e.clientY - this.startY) > 1 || Math.abs(e.clientX - this.startX) > 1)) {
            const i = this.getOptionIndex(e.target);
            if (i >= 0) {
                this.highlight(i);
            }
        }
    }

    private optionsUpHandler = (e: MouseEvent): void => {
        if (!this.disabled && (this.startX === undefined || this.startY === undefined || Math.abs(e.clientY - this.startY) > 1 || Math.abs(e.clientX - this.startX) > 1)) {
            const i = this.getOptionIndex(e.target);
            if (i >= 0) {
                this.highlight(i);
                this.close();
            }
        }
    }

    private windowCloseHandler = (e: MouseEvent): void => {
        if (!(e.target instanceof Node && this.selectedElement.contains(e.target)) && this.getOptionIndex(e.target) < 0) {
            console.debug('OPTION_INDEX', this.getOptionIndex(e.target));
            this.close();
        }
    }

    private windowUpHandler = () => {
        this.optionsElement.removeEventListener('mousemove', this.optionsMoveHandler);
        this.optionsElement.addEventListener('mouseup', this.optionsUpHandler);
        this.backgroundElement?.removeEventListener('mouseup', this.windowUpHandler);
    }

    private getIconSpan(hidden: boolean) {
        return this.menuIcon ? <span className={`${this.name}-dropdown-icon`} hidden={hidden}>{icon(this.menuIcon).node}</span> : <span/>;
    }

    public constructor({name, parent, disabled=false, menuIcon, selectedClass='', optionClass='', listParent}: {name: string, parent: Node, disabled?: boolean, menuIcon?: IconDefinition, selectedClass?: string, optionClass?: string, listParent?: Node}) {
        this.name = name;
        this.disabled = disabled;
        this.optionsElement = <ul className={`${this.name}-dropdown-options`} hidden={true}/>;
        //this.selectedElement = <div className={`${this.name}-dropdown-selected config-user200-bg-hover`} aria-expanded="false">
        this.selectedElement = <div className={`${this.name}-dropdown-selected`} aria-expanded="false">&nbsp;</div>
        //this.selectElement = <div className={`${this.name}-dropdown ${configSafeTextBox}`} tabIndex={0} aria-disabled="true">
        this.selectElement = <div className={`${this.name}-dropdown ${selectedClass}`} tabIndex={0} aria-expanded="false" aria-disabled="true">{this.selectedElement}</div>;
        this.selectElement.addEventListener('keydown', this.keyHandler);
        this.selectedElement.addEventListener('mousedown', this.selectedDownHandler);
        this.backgroundElement = window.document.body; //document.getElementById('answers-inner');
        this.menuIcon = menuIcon;
        this.optionClass = optionClass;
        if (listParent) {
            listParent.appendChild(this.optionsElement);
        } else {
            this.selectElement.appendChild(this.optionsElement);
        }
        parent.appendChild(this.selectElement);
    }

    public addOption(optionName: string, optionDetails?: string, backendId?: string): void {
        const elem = optionDetails
            ? <li className={`${this.name}-dropdown-item ${this.optionClass}`} data-bid={backendId !== undefined ? backendId: ''} data-optionname={optionName}>
                <div className="option-name">{this.getIconSpan(true)}<Html html={optionName}/></div>
                <div className="option-details"><Html html={optionDetails}/></div>
            </li>
            : <li className={`${this.name}-dropdown-item ${this.optionClass}`} data-bid={backendId !== undefined ? backendId: ''} data-optionname={optionName}>
                <div className="option-name" style={{columnSpan: 'all'}}>{this.getIconSpan(true)}<Html html={optionName}/></div>
                <div/>
            </li>
        this.optionsElement.appendChild(elem);
        this.options.push(optionName);
    }

    public disable(disabled: boolean): void{
        this.disabled = disabled;
        this.selectElement.setAttribute('aria-disabled', String(disabled));
        for (let i = 0; i < this.optionsElement.children.length; ++i) {
            const option = this.optionsElement.children[i];
            if (option instanceof HTMLButtonElement) {
                option.disabled = disabled;
            }
        }
    }

    public hide(hidden: boolean): void {
        this.selectElement.hidden = hidden;
    }

    /** Free the resources used by LongtextQuestion */
    public destroy(): void {
        this.backgroundElement?.removeEventListener('mousedown', this.windowCloseHandler);
        this.backgroundElement?.removeEventListener('mouseup', this.windowUpHandler);
        this.selectElement.removeEventListener('keydown', this.keyHandler);
        this.selectedElement.removeEventListener('mousedown', this.selectedDownHandler);
        this.optionsElement.removeEventListener('mousedown', this.optionsDownHandler);
        this.optionsElement.removeEventListener('mousemove', this.optionsMoveHandler);
        this.optionsElement.removeEventListener('mouseup', this.optionsUpHandler);
        removeNode(this.selectElement);
    }

    public focus(): void {
        scrollRangeIntoView(this.selectElement, this.selectElement, true);
    }

    /** Get the answer value */
    public selectedIndex(): number {
        return this.index;
    }

    public selectByIndex(index: number): void {
        if (this.index != index) {
            this.highlight(index);
            removeChildren(this.selectedElement)
            this.selectedElement.appendChild(this.getIconSpan(false));
            this.selectedElement.appendChild(<Html html={this.options[index]}/>);
            this.index = index;
        }
    }

    public selectByName(name: string): void {
        for (let i = 0; i < this.options.length; ++i) {
            if (name === this.options[i]) {
                this.selectByIndex(i);
                break;
            }
        }
    }
}