import { mkNode, removeNode } from '@p4b/utils';
import { faPalette, faSquare } from '@fortawesome/free-solid-svg-icons';
import { ControlPanel } from '@p4b/question-base';
import { translate } from '@p4b/utils-lang';
import ResizeObserver from 'resize-observer-polyfill';
import { version } from '@gen/version';
import 'pepjs';

const debugVersion = version.match(/^\S+\s+DEBUG\s+\[(\S+\s+)+(\d+):(\d+):\d+(\s+\S+)+\]$/);

//----------------------------------------------------------------------------------------
// Global style management

const dstyle = document.createElement('style');
dstyle.id = 'dynamic-theme';
document.head.appendChild(dstyle);
const classIndex: {[index: string]: number} = {};
let rule = 0;

export function setStyle(className: string, styles: Partial<CSSStyleDeclaration>): void {
    if (dstyle.sheet instanceof CSSStyleSheet) {
        let cssRule;
        if (!(className in classIndex)) {
            classIndex[className] = rule;
            dstyle.sheet.insertRule(`.${className} {}`, rule++);
            cssRule = dstyle.sheet.cssRules[rule - 1];
        } else {
            cssRule = dstyle.sheet.cssRules[classIndex[className]];
        }
        if (cssRule instanceof CSSStyleRule) {
            for (const x in styles) {
                const style = styles[x];
                if (style !== undefined) {
                    cssRule.style[x] = style;
                }
            }
        }
    }
}


//-----------------------------------------------------------------------------------
// Custom slider control

class Slider {
    private readonly canvas: HTMLCanvasElement;
    private readonly ondraw: (context: CanvasRenderingContext2D, x: number) => void;
    private readonly ondone: () => void;
    private readonly resizeObserver: ResizeObserver;
    private value: number;
    private isDown = false;

    private readonly down = (event: PointerEvent) => {
        this.canvas.setPointerCapture(event.pointerId);
        const {left, width} = this.canvas.getBoundingClientRect();
        this.setAndDraw((event.clientX - left) / width);
        this.isDown = true;
    }

    private readonly move = (event: PointerEvent) => {
        if (this.isDown) {
            const {left, width} = this.canvas.getBoundingClientRect();
            this.setAndDraw((event.clientX - left) / width);
        }
    }

    private readonly up = () => {
        this.isDown = false;
    }

    private readonly cancel = () => {
        this.isDown = false;
    }

    private readonly resize = (entries: ResizeObserverEntry[]): void => {
        const l = entries.length;
        if (l > 0) {
            const dpr = window.devicePixelRatio || 1;
            const {width, height} = entries[l-1].contentRect;
            this.canvas.width = dpr * width;
            this.canvas.height = dpr * height;
            this.draw();
        }
    }

    private timestamp?: number;

    private readonly frame = (timestamp: number): void => {
        if (timestamp !== this.timestamp) {
            this.timestamp = timestamp;
            const context = this.canvas.getContext('2d');
            if (context) {
                const dpr = 2 * (window.devicePixelRatio || 1);
                context.lineWidth = dpr;
                this.ondraw(context, this.value);
            }
        }
    }

    private readonly frameWithCallback = (timestamp: number): void => {
        this.frame(timestamp);
        this.ondone();
    }

    private setAndDraw(value: number): void {
        if (this.value !== value) {
            this.set(value);
            requestAnimationFrame(this.frameWithCallback);
        }
    }

    constructor(id: string, parent: Element, value: number, ondraw: (context: CanvasRenderingContext2D, value: number) => void, ondone: () => void) {
        this.canvas = mkNode('canvas', {id, className: 'slider-canvas', parent, attrib: {
            'touch-action': 'none',
        }});
        this.value = value;
        this.ondraw = ondraw;
        this.ondone = ondone;
        this.resizeObserver = new ResizeObserver(this.resize);
        this.resizeObserver.observe(this.canvas);
        this.canvas.addEventListener('pointerdown', this.down);
        this.canvas.addEventListener('pointermove', this.move);
        this.canvas.addEventListener('pointerup', this.up);
        this.canvas.addEventListener('pointercancel', this.cancel);
    }

    public destroy() {
        this.resizeObserver.disconnect();
        this.canvas.removeEventListener('pointerdown', this.down);
        this.canvas.removeEventListener('pointermove', this.move);
        this.canvas.removeEventListener('pointerup', this.up);
        this.canvas.removeEventListener('pointercancel', this.cancel);
    }

    public set(value: number) {
        if (value < 0) {
            value = 0;
        } else if (value > 1) {
            value = 1;
        }
        this.value = value;
    }

    public draw() {
        requestAnimationFrame(this.frame);
    }
}


//---------------------------------------------------------------------------------------------------
// HC*L* Colour Model - L* is directly comparable to W3C contrast-ratio.
//
// W3C contrast-ratio applies inverseGamma, calculates relative-luminance, and then calculates contrast ratio.
// This colour model applies inverseGamma, then transforms from RGB to YUV. By using the BT.709 colourspace the
// Y component is exactly the W3C relative-luminance, and then scaling to perceptual lightness (L*) creates a scale
// where the contrast-ratio between two colours can be found simply be the absolute value of the difference in the
// colours' L* component.

type HCL = {hue: number, chr: number, lum: number};
type RGB = {red: number, green: number, blue: number, alpha?: number};
type YUV = {y: number, u: number, v: number};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isHcl(x: any): x is HCL {
    return x && typeof x === 'object'
        && typeof x.hue === 'number'
        && typeof x.chr === 'number'
        && typeof x.lum === 'number';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isRgb(x: any): x is RGB {
    return x && typeof x === 'object'
        && typeof x.red === 'number'
        && typeof x.green === 'number'
        && typeof x.blue === 'number';
}

function toString({red, green, blue, alpha = 1}: RGB) {
    if (alpha < 1) {
        return `rgba(${red >> 0},${green >> 0},${blue >> 0},${alpha})`;
    } else {
        return `rgb(${red >> 0},${green >> 0},${blue >> 0})`;
    }
}

function toHex({red, green, blue, alpha = 1}: RGB) {
    if (alpha < 1) {
        return '#' + [red, green, blue, Math.round(alpha * 255)].map(x => x.toString(16).padStart(2, '0')).join('')
    } else {
        return '#' + [red, green, blue].map(x => x.toString(16).padStart(2, '0')).join('')
    }
}

function fmod(x: number, y: number): number {
    const z = x - Math.floor(x / y) * y
    return (z < 0) ? z + y : z;
}

// sRGB Gamma Companding

function inverseGamma(v: number): number {
    v /= 255;
    return  (v <= 0.04045) ? (v / 12.92) : ((v + 0.055) / 1.055) ** 2.4;
}

function applyGamma(w: number): number {
    return Math.round(Math.max(Math.min(255 * ((w <= 0.0031308) ? (w * 12.92) : (1.055 * w ** (1 / 2.4) - 0.055)), 255), 0));
}

// Normalised C*/Y* Perceptial Companding

const Y1 = 216 / 24389;
const Y2 = 24389 / 2700;
function toStar(y: number): number {
    if (y <= Y1) {
        return Y2 * y;
    } else {
        return (y ** (1/3) * 1.16 - 0.16);
    }
}

function fromStar(l: number): number {
    if (l > 0.08) {
        return ((l + 0.16) / 1.16) ** 3;
    } else {
        return l / Y2;
    }
}

/*
const minY = Math.log2(0.05);
const difY = Math.log2(1.05) - minY;

function toStar(y: number): number {
    return (Math.log2(y + 0.05) - minY) / difY;
}

function fromStar(y: number): number {
    return 2**(y * difY + minY) - 0.05;
}
*/

/*
// Get distance to unit square for polar angle for chroma UV scaling.
function unitSquareDistanceForAngle(angle: number): number {
    return 1 / Math.cos(((angle + 45) % 90 - 45) / 180 * Math.PI);
}
*/

const root2 = Math.sqrt(2);

// BT.709
const Kr = 0.2126729;
const Kb = 0.0721750;
const Kg = 1 - Kb - Kr; // 0.7151521
const a1 = 0;
const b1 = 2 - 2 * Kr;
const a2 = -(Kb / Kg) * (2 - 2 * Kb);
const b2 = -(Kr / Kg) * (2 - 2 * Kr);
const a3 = 2 - 2 * Kb;
const b3 = 0;

function hclToYuv({hue, chr, lum}: HCL): YUV {
    const r = fromStar(chr) / root2;
    const a = fmod(hue + 102.91012733297319, 360) * Math.PI / 180;
    return {
        y: Math.max(Math.min(fromStar(lum), 1), 0),
        u: r * Math.cos(a),
        v: r * Math.sin(a),
    };
}

function hclToRgb(hcl: HCL): RGB {
    const yuv = hclToYuv(hcl);
    const y = yuv.y;
    let {u, v} = yuv;
    // YUV to RGB luminance preserving clipping
    let uv1 = a1 * u + b1 * v;
    let uv2 = a2 * u + b2 * v;
    let uv3 = a3 * u + b3 * v;
    const c = Math.max(uv1, uv2, uv3);
    if (y + c > 1) {
        const s = (1 - y) / c;
        u = s * u;
        v = s * v;
    }
    uv1 = a1 * u + b1 * v;
    uv2 = a2 * u + b2 * v;
    uv3 = a3 * u + b3 * v;
    const d = Math.min(uv1, uv2, uv3);
    if (y + d < 0) {
        const s = (0 - y) / d;
        u = s * u;
        v = s * v;
    }
    // convert YUV to sRGB
    return {
        red: applyGamma(y + a1 * u + b1 * v),
        green: applyGamma(y + a2 * u + b2 * v),
        blue: applyGamma(y + a3 * u + b3 * v),
    };
}

const Krb = Kr / (1 - Kb); // 0.115 * 2
const Kgb = Kg / (1 - Kb); // 0.385 * 2
const Kgr = Kg / (1 - Kr); // 0.454 * 2
const Kbr = Kb / (1 - Kr); // 0.046 * 2

function yuvToHcl({y, u, v}: YUV): HCL {
    const h = fmod(Math.atan2(v, u) * 180 / Math.PI, 360);
    return {
        hue: fmod(h - 102.91012733297319, 360),
        chr: Math.max(Math.min(toStar(Math.sqrt(u * u + v * v) * root2), 1), 0),
        lum: Math.max(Math.min(toStar(y), 1), 0),
    };
}

function rgbToHcl({red, green, blue}: RGB): HCL {
    const r = inverseGamma(red), g = inverseGamma(green), b = inverseGamma(blue);
    return yuvToHcl({
        y: Kr * r + Kg * g + Kb * b,
        u: (b - Krb * r - Kgb * g) / 2,
        v: (r - Kgr * g - Kbr * b) / 2,
    });
}

function toHcl(colour: RGB | HCL): HCL {
    if (isRgb(colour)) {
        return rgbToHcl(colour);
    } else if (isHcl(colour)) {
        return {...colour};
    } else {
        throw new TypeError('Expected RGB or HCL colour');
    }
}

//---------------------------------------------------------------------------------------
// Colour Controls.

const p500 = rgbToHcl({red: 0x4a, green: 0xa9, blue: 0xce});
const i500 = rgbToHcl({red: 0x1a, green: 0xa1, blue: 0xff});
const s500 = rgbToHcl({red: 0x3e, green: 0xdb, blue: 0xbb});
const w500 = rgbToHcl({red: 0xff, green: 0x7c, blue: 0x1a});
const d500 = rgbToHcl({red: 0xff, green: 0x1a, blue: 0x1a});
const n500 = rgbToHcl({red: 0x8c, green: 0x8c, blue: 0x8d});

const pbase = rgbToHcl({red: 0x1f, green: 0x5b, blue: 0x73});
const ibase = rgbToHcl({red: 0x00, green: 0x1e, blue: 0x33});
const sbase = rgbToHcl({red: 0x10, green: 0x55, blue: 0x47});
const wbase = rgbToHcl({red: 0xff, green: 0x6d, blue: 0x00});
const dbase = rgbToHcl({red: 0xd0, green: 0x00, blue: 0x00});
const nbase = rgbToHcl({red: 0x4f, green: 0x4f, blue: 0x50});

const primary = {hue: i500.hue, chr: i500.chr, lum: ibase.lum};
const secondary = {hue: p500.hue, chr: p500.chr, lum: pbase.lum};
const info = {hue: i500.hue, chr: i500.chr, lum: ibase.lum};
const safe = {hue: s500.hue, chr: s500.chr, lum: sbase.lum};
const warn = {hue: w500.hue, chr: w500.chr, lum: wbase.lum};
const dngr = {hue: d500.hue, chr: d500.chr, lum: dbase.lum};
const ntrl = {hue: n500.hue, chr: n500.chr, lum: nbase.lum};

const hue = fmod(primary.hue - 180, 360);
const overlays = [
    {name: 'white', background: {hue, chr: 0, lum: 1}},
    {name: 'yellow', background: {red: 197, green: 168, blue: 0}},
    {name: 'orange', background: {red: 217, green: 117, blue: 44}},
    {name: 'rose', background: {red: 212, green: 108, blue: 123}},
    {name: 'pink', background: {red: 184, green: 111, blue: 168}},
    {name: 'purple', background: {red: 128, green: 118, blue: 191}},
    {name: 'blue', background: {red: 64, green: 138, blue: 191}},
    {name: 'aqua', background: {red: 35, green: 156, blue: 174}},
    {name: 'lime', background: {red: 112, green: 150, blue: 6}},
    {name: 'mint', background: {red: 26, green: 168, blue: 105}},
    {name: 'grey', background: {hue, chr: 0, lum: 0.55}}, // {red: 131, green: 128, blue: 123}},
    {name: 'dark', background: {hue, chr: 0, lum: 0}},
];

function lumArray({lum}: HCL): number[] {
    const base = ((lum > 0.5) ? 0.103 : 0.8);
    return [
        lum,                        // 000
        0.06 * base + 0.94 * lum, // 100
        0.16 * base + 0.84 * lum, // 200
        0.26 * base + 0.74 * lum, // 300
        0.36 * base + 0.64 * lum, // 400
        0.46 * base + 0.54 * lum, // 500
        0.56 * base + 0.44 * lum, // 600
        0.66 * base + 0.34 * lum, // 700
        0.8 * base + 0.2 * lum, // 800
        base,                                                           // 900
    ];
    /*return [
        lum,                        // 000
        0.111 * base + 0.888 * lum, // 100
        0.222 * base + 0.777 * lum, // 200
        0.333 * base + 0.666 * lum, // 300
        0.444 * base + 0.555 * lum, // 400
        0.555 * base + 0.444 * lum, // 500
        0.666 * base + 0.333 * lum, // 600
        0.777 * base + 0.222 * lum, // 700
        0.888 * base + 0.111 * lum, // 800
        base,                                                           // 900
    ];*/
}

function chrArray({chr}: HCL): number[] {
    //const y : number[] = [];
    //for (let x = 0; x < 11; x++) {
    //    y.push(chr * (-0.0011 * x ** 4 + 0.022 * x ** 3 - 0.177 * x ** 2 + 0.67 * x));
    //}
    //return y;
    /*return [
        chr * 0.00, // 000
        chr * 0.50, // 100
        chr * 0.80, // 200
        chr * 0.90, // 300
        chr * 0.95, // 400
        chr * 1.00, // 500
        chr * 1.00, // 600
        chr * 1.00, // 700
        chr * 1.00, // 800
        chr * 1.00, // 900
    ];*/
    return [
        chr * 0.00, // 000
        chr * 0.60, // 100
        chr * 0.70, // 200
        chr * 0.80, // 300
        chr * 0.90, // 400
        chr * 1.00, // 500
        chr * 1.00, // 600
        chr * 1.00, // 700
        chr * 1.00, // 800
        chr * 1.00, // 900
    ];
}

function setGradient(name: string, base: HCL, ls: number[], cs: number[]) {
    for (let i = 0; i < ls.length; ++i) {
        const lum = ls[i], chr = cs[i]
        , b = toHex(hclToRgb({hue: base.hue, chr, lum}))
        , t = toHex(hclToRgb({hue: base.hue, chr: base.chr, lum: (lum > 0.5) ? Math.max(0, lum - 0.8) : Math.min(1, lum + 0.8)}))
        , u = toHex(hclToRgb({hue: base.hue, chr: base.chr, lum: (lum > 0.5) ? lum - 0.5 : lum + 0.5}))
        //, v = toHex(hclToRgb({hue: base.hue, chr: base.chr, lum: (lum > 0.5) ? lum : lum - 0.25}))
        ;

        setStyle(`config-${name}${i}00-bg`, {backgroundColor: b});
        setStyle(`config-${name}${i}00-bg-hover:hover`, {backgroundColor: b});
        setStyle(`config-${name}${i}00-bg-pressed[aria-pressed="true"]`, {backgroundColor: b});
        setStyle(`config-${name}${i}00-bg-pressed-hover[aria-pressed="true"]:hover`, {backgroundColor: b});
        setStyle(`config-${name}${i}00-bg-selected[aria-selected="true"]`, {backgroundColor: b});
        setStyle(`config-${name}${i}00-bg-selected-hover[aria-selected="true"]:hover`, {backgroundColor: b});
        setStyle(`config-${name}${i}00-bg-invalid[aria-invalid="true"]`, {backgroundColor: b});
        setStyle(`config-${name}${i}00-bg-invalid-hover[aria-invalid="true"]:hover`, {backgroundColor: b});

        setStyle(`config-${name}${i}00-border`, {borderColor: b});
        setStyle(`config-${name}${i}00-border-pressed[aria-pressed="true"]`, {borderColor: b});
        setStyle(`config-${name}${i}00-border-pressed-focus[aria-pressed="true"]:focus`, {borderColor: b});
        setStyle(`config-${name}${i}00-border-selected[aria-selected="true"]`, {borderColor: b});
        setStyle(`config-${name}${i}00-border-selected-focus[aria-selected="true"]:focus`, {borderColor: b});
        setStyle(`config-${name}${i}00-border-invalid[aria-invalid="true"]`, {borderColor: b});
        setStyle(`config-${name}${i}00-border-invalid-focus[aria-invalid="true"]:focus`, {borderColor: b});

        setStyle(`config-${name}${i}00-outline-focus:focus`, {outlineColor: b, outlineStyle: 'dashed', outlineWidth: 'max(2px, 0.08em)', outlineOffset: '0.08em'});
        setStyle(`config-${name}${i}00-outline-pressed-focus[aria-pressed="true"]:focus`, {outlineColor: b, outlineStyle: 'dashed', outlineWidth: 'max(2px, 0.08em)', outlineOffset: '0.08em'});
        setStyle(`config-${name}${i}00-outline-selected-focus[aria-selected="true"]:focus`, {outlineColor: b, outlineStyle: 'dashed', outlineWidth: 'max(2px, 0.08em)', outlineOffset: '0.08em'});

        setStyle(`config-${name}${i}00aaa-text`, {color: t});
        setStyle(`config-${name}${i}00aaa-text-pressed[aria-pressed="true"]`, {color: t});
        setStyle(`config-${name}${i}00aaa-text-selected[aria-selected="true"]`, {color: t});
        setStyle(`config-${name}${i}00aaa-text-invalid[aria-invalid="true"]`, {color: t});
        setStyle(`config-${name}${i}00aaa-border`, {borderColor: t});
        setStyle(`config-${name}${i}00aaa-outline-focus:focus`, {outlineColor: t, outlineStyle: 'dashed', outlineWidth: 'max(2px, 0.08em)', outlineOffset: '0.08em'});
        setStyle(`config-${name}${i}00aaa-inline-focus:focus`, {outlineColor: t, outlineStyle: 'dashed', outlineWidth: 'max(2px, 0.08em)', outlineOffset: '-0.32em'});

        setStyle(`config-${name}${i}00aa-text`, {color: u});
        setStyle(`config-${name}${i}00aa-border`, {borderColor: u});
        setStyle(`config-${name}${i}00aa-border-pressed[aria-pressed="true"]`, {borderColor: u});
        setStyle(`config-${name}${i}00aa-border-pressed-focus[aria-pressed="true"]:focus`, {borderColor: u});
        setStyle(`config-${name}${i}00aa-border-selected[aria-selected="true"]`, {borderColor: u});
        setStyle(`config-${name}${i}00aa-border-selected-focus[aria-selected="true"]:focus`, {borderColor: u});

        setStyle(`config-${name}${i}00-fg`, {color: b});
        setStyle(`config-${name}${i}00-fg-invalid[aria-invalid="true"]`, {color: b});
    }
}

function setAppBackground(hcl: HCL): void {
    const lums = lumArray(hcl);

    setGradient('user', hcl, lums, new Array(10).fill(hcl.chr));
    setGradient('primary', secondary, lums, chrArray(secondary));
    setGradient('info', info, lums, chrArray(info));
    setGradient('safe', safe, lums, chrArray(safe));
    setGradient('warning', warn, lums, chrArray(warn));
    setGradient('danger', dngr, lums, chrArray(dngr));
    setGradient('neutral', ntrl, lums, chrArray(ntrl));

    setStyle('config-user000-alpha90', {backgroundColor: toHex({...hclToRgb(hcl), alpha: 0.9})});
    setStyle('config-info700-pressed-true', {backgroundColor: toHex(hclToRgb({hue: primary.hue, chr: primary.chr, lum: lums[7]}))});
    setStyle('config-info700-pressed-true:hover', {backgroundColor: toHex(hclToRgb({hue: primary.hue, chr: primary.chr, lum: lums[6]}))});

    const themeElement = document.head.querySelector('meta[name="theme-color"]');
    const c = hclToRgb({hue: primary.hue, chr: primary.chr, lum: lums[9]});
    themeElement?.setAttribute('content', '#' + ((1 << 24) + (c.red << 16) + (c.green << 8) + c.blue).toString(16).slice(1));
}

//if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
//    setAppBackground(toHcl(overlays[11].background));
//} else {
    setAppBackground(toHcl(overlays[0].background));
//}

function configPanel(name: string): string {
    return `
        config-${name}200-bg
        config-${name}200aa-text
        config-${name}200aa-border
    `;
}

function configGhostButton(name: string): string {
    return `
        navbutton
        config-${name}100-bg
        config-${name}100aa-border
        config-${name}100aaa-text
        config-${name}100aaa-inline-focus
        config-${name}200-bg-hover
    `;
}

function configGhostPress(name: string): string {
    return `
        navbutton
        config-${name}100-bg
        config-${name}200-bg-hover
        config-${name}100aa-border
        config-${name}100aaa-text
        config-${name}100aaa-inline-focus
        config-${name}400-bg-pressed
        config-${name}500-bg-pressed-hover
        config-${name}400aa-border-pressed
        config-${name}400aaa-text-pressed
    `;
}

function configSolidButton(name: string): string {
    return `
        navbutton
        config-${name}700-bg
        config-${name}900-border
        config-${name}700aaa-text
        config-${name}700aaa-inline-focus
        config-${name}800-bg-hover
    `;
}

function configInvalidButton(name: string): string {
    return `
        navbutton
        config-${name}700-bg-invalid
        config-${name}900-border-invalid
        config-${name}700aaa-text-invalid
        config-${name}700aaa-inline-focus
        config-${name}800-bg-invalid-hover
    `;
}

function configPressButton(name: string): string {
    return `
        config-${name}700-border
        config-${name}800-outline-focus
        config-user200-bg-hover
        config-${name}700-bg-pressed
        config-${name}700aaa-text-pressed
        config-user000aa-border-pressed
        config-${name}800-outline-pressed-focus
        config-${name}700aa-border-pressed-focus
        config-${name}800-bg-pressed-hover
    `;
}

function configTextBox(name: string): string {
    return `
        config-${name}700-border
        config-${name}800-outline-focus
        config-user000-bg
        config-user000aaa-text
    `;
}

function configToolPanel(name: string): string {
    return `
        config-${name}900-bg
        config-${name}900aaa-text
        config-user400-border
    `;
}

function configToolPanelReverse(name: string): string {
    return `
        config-${name}700-bg
        config-${name}700aaa-text
    `;
}

function configToolTab(name: string): string {
    return `
        app-button
        config-${name}900-bg
        config-${name}800-bg-hover
        config-${name}700-bg-pressed
        config-${name}600-bg-pressed-hover
        config-${name}900aaa-text
        config-${name}900aaa-inline-focus
    `;
}

function configToolTabReverse(name: string): string {
    return `
        config-${name}700-bg
        config-${name}800-bg-hover
        config-${name}700aaa-text
        config-${name}700aaa-border
        config-${name}900aaa-inline-focus
    `;
}

function configToolButton(name: string): string {
    return `
        config-${name}900-bg
        config-${name}800-bg-hover
        config-${name}700-bg-pressed
        config-${name}600-bg-pressed-hover
        config-${name}900aaa-text
        config-${name}900aaa-border
        config-${name}900aaa-inline-focus
    `;
}

function configToolText(name: string): string {
    return `
        config-${name}900aaa-border
        config-${name}900aaa-outline-focus
    `;
}

function configDropdown(name: string): string {
    return `
        config-${name}700-border
        config-${name}800-outline-focus
        config-user200-bg-hover
        config-${name}700-bg-selected
        config-${name}700aaa-text-selected
        config-user000aa-border-selected
        config-${name}800-outline-selected-focus
        config-${name}700aa-border-selected-focus
        config-${name}800-bg-selected-hover
    `;
}

export const configGhostPrimaryButton = configGhostButton('primary');
export const configGhostSafeButton = configGhostButton('safe');

export const configGhostPrimaryPress = configGhostPress('primary');

export const configSolidPrimaryButton = configSolidButton('primary');
export const configSolidSafeButton = configSolidButton('safe');
export const configSolidWarnButton = configSolidButton('warning');
export const configSolidDngrButton = configSolidButton('danger');
export const configSolidNtrlButton = configSolidButton('neutral');

export const configInvalidWarnButton = configInvalidButton('warning');

export const configSafeTextBox = configTextBox('safe');
export const configWarnTextBox = configTextBox('warning');
export const configDngrTextBox = configTextBox('danger');
export const configNtrlTextBox = configTextBox('neutral');

export const configPrimPress = configPressButton('primary');
export const configInfoPress = configPressButton('info');
export const configSafePress = configPressButton('safe');
export const configDngrPress = configPressButton('danger');
export const configNtrlPress = configPressButton('neutral');

export const configUserPanel = configPanel('user');
export const configInfoPanel = configPanel('info');
export const configWarnPanel = configPanel('warning');
export const configErrorPanel = configPanel('danger');

export const configInfoToolTab = configToolTab('info');
export const configUserToolTab = configToolTabReverse('user');
export const configInfoToolPanel = configToolPanel('info');
export const configUserToolPanel = configToolPanelReverse('user');
export const configInfoToolText = configToolText('info');
export const configInfoToolButton = configToolButton('info');

export const configSafeDropdown = configDropdown('safe');

function drawCaret(context: CanvasRenderingContext2D, x: number, h: number): void {
    context.fillStyle = 'black';
    context.strokeStyle = 'white';
    context.beginPath();
    context.moveTo(x, h / 2);
    context.lineTo(x + h / 2, h);
    context.lineTo(x - h / 2, h);
    context.closePath();
    context.stroke();
    context.fill();
}

function drawGradient(name: string, parent: HTMLDivElement) {
    mkNode('div', {parent, className: 'config-user000-bg', style: {margin: '1rem, 0', padding: '1rem 1rem 0 1rem'}, children: [
        mkNode('table', {style: {border: 'none', margin: '0'}, children: [
            mkNode('tr', {style: {border: 'none'}, children: [
                mkNode('td', {className: `config-${name}000-bg config-${name}000aa-text`, style: {width: '3rem', height: '3rem', border: 'none'}, children: [mkNode('text', {text: '000'})]}),
                mkNode('td', {className: `config-${name}100-bg config-${name}100aa-text`, style: {width: '3rem', height: '3rem', border: 'none'}, children: [mkNode('text', {text: '100'})]}),
                mkNode('td', {className: `config-${name}200-bg config-${name}200aa-text`, style: {width: '3rem', height: '3rem', border: 'none'}, children: [mkNode('text', {text: '200'})]}),
                mkNode('td', {className: `config-${name}300-bg config-${name}300aa-text`, style: {width: '3rem', height: '3rem', border: 'none'}, children: [mkNode('text', {text: '300'})]}),
                mkNode('td', {className: `config-${name}400-bg config-${name}400aa-text`, style: {width: '3rem', height: '3rem', border: 'none'}, children: [mkNode('text', {text: '400'})]}),
                mkNode('td', {className: `config-${name}500-bg config-${name}500aa-text`, style: {width: '3rem', height: '3rem', border: 'none'}, children: [mkNode('text', {text: '500'})]}),
                mkNode('td', {className: `config-${name}600-bg config-${name}600aa-text`, style: {width: '3rem', height: '3rem', border: 'none'}, children: [mkNode('text', {text: '600'})]}),
                mkNode('td', {className: `config-${name}700-bg config-${name}700aa-text`, style: {width: '3rem', height: '3rem', border: 'none'}, children: [mkNode('text', {text: '700'})]}),
                mkNode('td', {className: `config-${name}800-bg config-${name}800aa-text`, style: {width: '3rem', height: '3rem', border: 'none'}, children: [mkNode('text', {text: '800'})]}),
                mkNode('td', {className: `config-${name}900-bg config-${name}900aa-text`, style: {width: '3rem', height: '3rem', border: 'none'}, children: [mkNode('text', {text: '900'})]}),
            ]}),
        ]})
    ]});
}

export class Accessibility {
    private controlPanel: ControlPanel;
    private colourButton: HTMLButtonElement;
    private colourPanel: HTMLDivElement;
    private presets: HTMLDivElement;
    private hue: Slider;
    private sat: Slider;
    private lum: Slider;

    private colour = toHcl(overlays[0].background);

    private readonly handleColourButton = ():void => {
        const panel = this.controlPanel.panel();
        if (this.colourPanel.parentElement === panel) {
            panel.removeChild(this.colourPanel);
        } else {
            panel.appendChild(this.colourPanel);
        }
    }

    private readonly handleColourChoice = (event: Event):void => {
        let target = event.target;
        while (target instanceof Node) {
            if (target instanceof HTMLElement && target.dataset.colour) {
                for (const overlay of overlays) {
                    if (target.dataset.colour === overlay.name) {
                        if (isHcl(overlay.background)) {
                            console.log('BG', overlay.background);
                            this.colour = {...overlay.background};
                        } else if (isRgb(overlay.background)) {
                            this.colour = rgbToHcl(overlay.background);
                        }
                        console.log('PRESET', this.colour);
                        this.hue.set(this.colour.hue / 360);
                        this.hue.draw();
                        this.sat.set(this.colour.chr);
                        this.sat.draw();
                        this.lum.set(this.colour.lum);
                        this.lum.draw();
                        setAppBackground(this.colour);
                        return;
                    }
                }
                return;
            }
            target = target.parentElement;
        }
    }

    constructor(controlPanel: ControlPanel) {
        this.controlPanel = controlPanel;
        this.colourButton = mkNode('button', {
            className: configInfoToolTab,
            children: [
                mkNode('icon', {icon: faPalette}),
                mkNode('span', {className: 'app-button-text', children: [
                    mkNode('text', {text: translate('CONTROL_COLOUR')})
                ]}),
            ]
        });
        this.colourPanel = mkNode('div', {className: 'tool-bar-vbox'});
        this.presets = mkNode('div', {className: 'tool-bar-hbox', parent: this.colourPanel});
        for (const overlay of overlays) {
            const colour = toString(isHcl(overlay.background) ? hclToRgb(overlay.background) : overlay.background);
            const button = mkNode('button', {className: 'app-button config-primary-hover config-primary-fg-shadow-focus', parent: this.presets, children: [
                mkNode('icon', {icon: faSquare, style: {color: `${colour}`, fontSize: '28px'}}),
                mkNode('span', {className: 'app-button-text ', children: [
                    mkNode('text', {text: overlay.name.toUpperCase()})
                ]}),
            ]});
            button.dataset.colour = overlay.name;
        }
        mkNode('label', {for: 'hue', className: 'slider-label', parent: this.colourPanel, children: [
            mkNode('text', {text: 'Hue'}),
        ]});
        this.hue = new Slider('hue', this.colourPanel, this.colour.hue / 360, (context, hue) => {
            //const {chr, lum} = this.colour
            const w = context.canvas.width
            , h = context.canvas.height
            , s = 360 / w
            ;
            for (let x = 0; x < w; ++x) {
                context.fillStyle = toString(hclToRgb({hue: s * x, chr: 1, lum: 0.5}));
                //context.fillStyle = toString(hclToRgb({hue: s * x, chr, lum}));
                context.fillRect(x, 0, 1, h);
            }
            drawCaret(context, w * hue, h);
            this.colour.hue = 360 * hue;
        }, () => {
            this.sat.draw();
            this.lum.draw();
            setAppBackground(this.colour);
        });
        mkNode('label', {for: 'sat', className: 'slider-label', parent: this.colourPanel, children: [
            mkNode('text', {text: 'Saturation'}),
        ]});
        this.sat = new Slider('sat', this.colourPanel, this.colour.chr, (context, chr) => {
            const {hue /*, lum*/} = this.colour
            , w = context.canvas.width
            , h = context.canvas.height
            , s = 1 / w
            ;
            for (let x = 0; x < w; ++x) {
                context.fillStyle = toString(hclToRgb({hue, chr: s * x, lum: 0.5}));
                //context.fillStyle = toString(hclToRgb({hue, chr: s * x, lum}));
                context.fillRect(x, 0, 1, h);
            }
            drawCaret(context, w * chr, h);
            this.colour.chr = chr;
        }, () => {
            //this.hue.draw();
            this.lum.draw();
            setAppBackground(this.colour);
        });
        mkNode('label', {for: 'lum', className: 'slider-label', parent: this.colourPanel, children: [
            mkNode('text', {text: 'Lightness'}),
        ]});
        this.lum = new Slider('lum', this.colourPanel, this.colour.lum, (context, lum) => {
            const {hue, chr} = this.colour
            , w = context.canvas.width
            , h = context.canvas.height
            , s = 1 / w
            ;
            for (let x = 0; x < w; ++x) {
                context.fillStyle = toString(hclToRgb({hue, chr, lum: s * x}));
                context.fillRect(x, 0, 1, h);
            }
            drawCaret(context, w * lum, h);
            this.colour.lum = lum;
        }, () => {
            //this.hue.draw();
            //this.sat.draw();
            setAppBackground(this.colour);
        });
        this.presets.addEventListener('click', this.handleColourChoice);
        this.colourButton.addEventListener('click', this.handleColourButton);
        this.controlPanel.add(this.colourButton);

        if (debugVersion) {
            drawGradient('user', this.colourPanel);
            drawGradient('primary', this.colourPanel);
            drawGradient('info', this.colourPanel);
            drawGradient('safe', this.colourPanel);
            drawGradient('warning', this.colourPanel);
            drawGradient('danger', this.colourPanel);
            drawGradient('neutral', this.colourPanel);
        }
    }

    public disable(disabled: boolean): void {
        this.colourButton.disabled = disabled;
        if (disabled) {
            removeNode(this.colourPanel);
        }
    }

    public destroy(): void {
        this.hue.destroy();
        this.sat.destroy();
        this.lum.destroy();
        this.presets.removeEventListener('click', this.handleColourChoice);
        this.colourButton.removeEventListener('click', this.handleColourButton);
        this.controlPanel.remove(this.colourButton);
    }
}
