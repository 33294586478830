import autosize from 'autosize';
import { LocalData } from '@p4b/exam-service';
import { mkNode, scrollRangeIntoView, removeNode, newUniqueName } from '@p4b/utils';
import { Question, QuestionContext, QuestionManifest, QuestionBase, Expr, registerAnswerType, QuestionArgs } from '@p4b/question-base';
import { Lightbox } from '@p4b/lightbox';
import { configSafeTextBox } from '@p4b/exam-accessibility';

/** Longtext question textarea UI */
export class QuestionText extends QuestionBase implements Question {
    //private ai: LongtextUi;

    private answerItem: HTMLDivElement;
    //private answerLabel: HTMLDivElement;
    private textarea: HTMLTextAreaElement | HTMLInputElement;
    private wordcount: HTMLDivElement;
    private enableCopyPaste: boolean;

    private isLong: boolean;
    private value: string;
    private updateVisibility: () => void;
    private autosave?: number;

    public readonly visibilityExpression?: Expr;

    /** Construct LontextQuestion UI */
    public constructor(args: Omit<QuestionArgs, 'showFlag'> & {
        updateVisibility: () => void,
        visibilityExpression?: Expr,
        isLong: boolean,
        minLines?: number,
    }) {
        super({...args, showFlag: true});
        const {indent, isLong, minLines, updateVisibility, visibilityExpression, context} = args;
        const indentRem = (1.6 * (indent ?? 0) + 1.6).toString();
        this.label.style.paddingLeft = `${indentRem}rem`;
        this.answerItem = mkNode('div', {className: 'answer-item', parent: this.column});
        //this.answerLabel = mkNode('div', {className: 'answer-label', parent: this.answerItem});
        if (isLong) {
            this.textarea = mkNode('textarea', {className: 'long-answer ' + configSafeTextBox, parent: this.answerItem,
                attrib:{rows: minLines ? minLines.toString() : '3', autocomplete: newUniqueName(), autocorrect: "off", autocapitalize: "off", spellcheck: "false"}
            });
        } else {
            this.textarea = mkNode('input', {className: 'short-answer ' + configSafeTextBox, parent: this.answerItem,
                attrib:{type: 'text', autocomplete: newUniqueName(), autocorrect: 'off', autocapitalize: 'off', spellcheck: 'false'}
            });
        }
        this.wordcount = mkNode('div', {className: 'wordcount', parent: this.answerItem});
        this.wordcount.innerHTML = '_ words';
        this.updateVisibility = updateVisibility;
        this.visibilityExpression = visibilityExpression;
        //this.textarea.name = label;
        this.textarea.disabled = true;
        this.value = '';
        this.isLong = isLong;
        this.enableCopyPaste = context.meta?.enableCopyPaste ?? false;
        //frag.appendChild(this.answerItem);
    }

    /** Load any stored answer */
    public loadAnswer(response?: LocalData): void {
        try {
            if (response && typeof response.answer === 'string') {
                this.value = response.answer;
                this.textarea.value = response.answer;
            }
            this.updateVisibility();
        } catch(e) {
            console.error(String(e));
        }
    }

    private visibilityChange?: string;
    private isHidden?: () => boolean;

    private addVisibilityListener(handler: (_:Event) => void): void {
        if (document.hidden !== undefined) { // Opera 12.10 and Firefox 18 and later support
            this.isHidden = () => document.hidden;
            this.visibilityChange = "visibilitychange";
        } else if (document.msHidden !== undefined) {
            this.isHidden = () => document.msHidden as boolean;
            this.visibilityChange = "msvisibilitychange";
        } else if (document.webkitHidden !== undefined) {
            this.isHidden = () => document.webkitHidden as boolean;
            this.visibilityChange = "webkitvisibilitychange";
        }
        if (this.visibilityChange) {
            window.addEventListener(this.visibilityChange, handler);
        }
    }

    private removeVisibilityListener(handler: (_:Event) => void): void {
        if (this.visibilityChange) {
            window.removeEventListener(this.visibilityChange, handler);
        }
    }

    public loadingComplete(): void {
        super.loadingComplete();
        if (this.isLong) {
            autosize(this.textarea);
        }
        this.textarea.disabled = this.isLoading || this.isReadOnly;
        this.textarea.addEventListener('change', this.change_handler);
        this.textarea.addEventListener('input', this.update_handler);
        this.addVisibilityListener(this.visibility_handler);
        if (this.isLong) {
            this.autosave = window.setInterval(this.change_handler, 30000);
        }
        if (!this.enableCopyPaste) {
            this.textarea.addEventListener('paste', this.paste_handler);
            this.textarea.addEventListener('drop', this.paste_handler);
        }
        this.update_handler();
    }

    public setReadOnly(isReadOnly: boolean): void {
        this.change_handler();
        super.setReadOnly(isReadOnly);
        this.textarea.disabled = this.isLoading || this.isReadOnly;
    }

    /** Get the answer value */
    public getValue(): string {
        return this.value;
    }

    /** Free the resources used by LongtextQuestion */
    public destroy(): void {
        removeNode(this.answerItem);
        if (this.autosave) {
            window.clearInterval(this.autosave);
            this.autosave = undefined;
        }
        this.textarea.removeEventListener('change', this.change_handler);
        this.textarea.removeEventListener('input', this.update_handler);
        this.removeVisibilityListener(this.visibility_handler);
        if (!this.enableCopyPaste) {
            this.textarea.removeEventListener('paste', this.paste_handler);
            this.textarea.removeEventListener('drop', this.paste_handler);
        }
        if (this.isLong) {
            autosize.destroy(this.textarea);
        }
        super.destroy();
    }

    //public getAnswer(): AnswerKey & AnswerValue {
    //    return {qno: this.qno, ano: this.ano, answer: this.value || null};
    //}

    public focus(): void {
        scrollRangeIntoView(this.answerItem, this.answerItem, true);
        this.textarea.focus();
    }

    private readonly update_handler = (): void => {
        const l = (this.textarea.value.match(/\w+/g) ?? []).length;
        this.wordcount.innerHTML = l + ((l == 1) ? ' word' : ' words');
    }

    private readonly change_handler = async (): Promise<void> => {
        if (!this.isDisabled() && this.value != this.textarea.value) {
            const value = this.textarea.value;
            this.value = value;
            try {
                //this.context.setNavigating(true);
                await this.context.responses.saveAnswer({qno: this.qno, ano: this.ano}, {answer: value || null});
            } catch (err) {
                console.error(String(err));
            } finally {
                //this.context.setNavigating(false);
                this.updateVisibility();
            }
        }
    }

    private hidden = false;
    private readonly visibility_handler = async ({type}: Event) => {
        console.debug('VISIBILITY', type);
        switch (this.isHidden?.()) {
            case true:
                if (!this.hidden) {
                    this.hidden = true;
                    await this.change_handler();
                }
                break;
            case false:
                this.hidden = false;
                break;
            default:
                break;
        }
    }

    private readonly paste_handler = (event: Event): void => {
        event.preventDefault();
    }
}

registerAnswerType({
    name: 'Text',
    isThis: (answer: PractiqueNet.ExamJson.Definitions.Answer): boolean => {
        return answer.type.toLowerCase() === 'longtext' || answer.type.toLowerCase() === 'text';
    },
    makeAnswer: (
        qno: number,
        context: QuestionContext,
        updateVisibility: () => void,
        question: QuestionManifest,
        answer: PractiqueNet.ExamJson.Definitions.Answer,
        frag: DocumentFragment,
        ano: number,
        lightbox: Lightbox,
        isRemoteShowHide: boolean,
        isOSCE: boolean,
    ) => new QuestionText({
        updateVisibility,
        context,
        qno,
        ano,
        backendQid: question.manifest.backend_id,
        backendAid: answer.backend_id,
        showNumber: question.manifest.answers.length > 1,
        label: answer.label,
        frag,
        isLong: answer.type.toLowerCase() === 'longtext',
        lightbox,
        isRemoteShowHide,
        minLines: (answer.type === 'longtext' || answer.type === 'text') ? answer.minimumLines : 0,
        notes: answer.notes,
        indent: answer.indent,
        visibilityExpression: answer.visible,
        resources: question.answersResources[ano],
        mandatory: answer.mandatory,
        type: answer.type.toLowerCase(),
        isOSCE,
    })
});
