import { mkNode } from '@p4b/utils';
import { Img } from '@p4b/image-base';
import { Thumbnail, ThumbnailBase } from '@p4b/thumbnail-base';

export class ResourceAudio extends ThumbnailBase implements Thumbnail {
    private audio: HTMLAudioElement;
    private rows: number;

    public hide(hidden: boolean): void {
        if (document.fullscreenElement === this.audio) {
            document.exitFullscreen();
        }
        if (!this.audio.paused) {
            this.audio.pause();
        }
        super.hide(hidden);
    }


    public constructor(image: Img, frag: Node, releaseButton = false) {
        const rows = 120;
        let audio;
        super(frag, image, releaseButton, (figure) => {
            audio = mkNode('audio', {
                className: 'thumbnail',
                parent: figure,
                style: {visibility: 'hidden', height: rows + 'px'},
                attrib: {controls: 'true', preload: 'metadata'},
            });
        });
        this.rows = rows;
        if (!audio) {
            throw "Thumbnail audio is null";
        }
        this.audio = audio;
    }

    public async load(buffers: ArrayBuffer|null): Promise<void> {
        const mime = this.image.mime;
        if (buffers) {
            const blob = new Blob([buffers], {type: mime ?? ''});
            this.audio.src = URL.createObjectURL(blob);
        } else {
            console.error('audio steam null');
        }
        this.audio.style.visibility = 'visible';
    }

    public open() {
        return;
    }

    public async defferedLoad(): Promise<void> {
        return;
    }

    public destroy(): void {
        super.destroy();
	if (!this.audio.paused) {
            this.audio.pause();
        }
        if (this.audio.src) {
            console.log('REVOKE AUDIO URL');
            URL.revokeObjectURL(this.audio.src);
            this.audio.src = '';
        }
    }
}
