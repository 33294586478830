import { version } from '@gen/version';
import { removeChildren, isIndexed} from '@p4b/utils';
import { cleanUpOldExam, runExam, ExamState, deleteCurrentExam } from '@p4b/exam-viewer';
import { dbGet, dbPut, dbClear, dbClearLogin, dbClearSelect } from '@p4b/utils-db';
import { setCredentials, clearCredentials, HttpError, getText, setUUID } from '@p4b/utils-net';
import { Json, StoreChunks, deleteExam, getExamList, State, ExamMap, RemoteExamItem, Structure, deleteUnavailable, getFetchList, fetchExams, getLocalExams, updateExamPins } from '@p4b/exam-service';
import { DecryptionError } from './utils-zip';
import { AssignmentViewer, ChosenExam, Logout } from '@p4b/assignment-viewer';
import { Auth } from '@p4b/utils-login';
import { Progress } from '@p4b/utils-progress';
import { alertModal, errorModal } from "@p4b/modal-dialog";
import { initTranslation, translate, localise, setLanguage, getLanguages, getCurrentLanguage } from '@p4b/utils-lang';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DOMcreateElement } from '@p4b/utils-jsx';
import { v4 as genUuid } from 'uuid';
import { Dropdown } from '@p4b/component-dropdown';

declare global {
    interface Window {
        webkit: {
            messageHandlers: {
                iOS: {
                    postMessage: Json;
                }
            }
        }
    }
    interface Document {
        msHidden: boolean|undefined;
        webkitHidden: boolean|undefined;
    }
}

//getText('version.txt').then(version => {
//    alert(`VERSION ${version}`);
//})

window.addEventListener('contextmenu', canvasContextmenuHandler, {passive: false});

function canvasContextmenuHandler(event: MouseEvent): boolean {
    event.preventDefault();
    event.stopPropagation();
    return false;
}


//----------------------------------------------------------------------------
// Handle multiple open tabs.

if (window.BroadcastChannel) {
    // If BroadcastChannel available, then when new p4b tab opened, pause other
    // p4b tabs already open with modal dialog and reload when dialog closed.
    const bc = new BroadcastChannel('p4b');
    bc.onmessage = async (e) => {
        if (e.data === 'open') {
            await initTranslation();
            await alertModal(translate('P4B_BACKGROUNDED'));
            window.location.reload();
        }
    };
    bc.postMessage('open');
} else {
    // If BroadcastChannel not available, reload p4b tabs when transitioning
    // from not-visible to visible.
    let visibilityChange: string|undefined;
    let isHidden: (() => boolean)|undefined;

    if (document.hidden !== undefined) { // Opera 12.10 and Firefox 18 and later support
        isHidden = () => document.hidden;
        visibilityChange = "visibilitychange";
    } else if (document.msHidden !== undefined) {
        isHidden = () => document.msHidden as boolean;
        visibilityChange = "msvisibilitychange";
    } else if (document.webkitHidden !== undefined) {
        isHidden = () => document.webkitHidden as boolean;
        visibilityChange = "webkitvisibilitychange";
    }

    if (visibilityChange) {
        window.addEventListener(visibilityChange, () => {
            if (isHidden?.() === false) {
                window.location.reload();
            }
        });
    }
}

//----------------------------------------------------------------------------


//import { ExamItem } from './p4b-api';

//import { Proctor } from './exam-proctor';

/*
function singleApplicationModeStartOnSuccess(): void {
    //alertModal('Single application mode started');
    SAM = true;state
}

function singleApplicationModeStartOnError(): void {
    //alertModal('Single application mode not available');
}

function singleApplicationModeExitOnSuccess(): void {
    //alertModal('Single application mode exited');
    SAM = false;
}

function singleApplicationModeExitOnError(): void {
    //alertModal('Single application mode exit failed');

}
*/

/*
if (debugVersion) {
    const {debug, info, log, warn, error} = console;
    window.console.debug = (...args: unknown[]): void => {
        //debug(JSON.stringify([...args]));
        //setImmediate(async () => {
        //    try {
        //        await requestJson('/log', ['DEBUG', performance.now() / 1000, ...args]);
        //    } catch (err) {}
        //});
    }
    window.console.info = (...args: unknown[]): void => {
        //info(JSON.stringify([...args]));
        //setImmediate(async () => {
        //    try {
        //        await requestJson('/log', [' INFO', performance.now() / 1000, ...args]);
        //    } catch (err) {}
        //});
    }
    window.console.log = (...args: unknown[]): void => {
        //log(JSON.stringify([...args]));
        //setImmediate(async () => {
        //    try {
        //        await requestJson('/log', ['  LOG', performance.now() / 1000, ...args]);
        //    } catch (err) {}
        //});
    }
    window.console.warn = (...args: unknown[]): void => {
        warn(JSON.stringify([...args]));
        //setImmediate(async () => {
        //    try {
        //        await requestJson('/log', [' WARN', performance.now() / 1000, ...args]);
        //    } catch (err) {}
        //});
    }
    window.console.error = (...args: unknown[]): void => {
        error(JSON.stringify([...args]));
        //setImmediate(async () => {
        //    try {
        //        await requestJson('/log', ['ERROR', performance.now() / 1000, ...args]);
        //    } catch (err) {}
        //});
    }
}
*/

function singleApplicationModeStart(): void {
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.iOS) {
        //window.webkit.messageHandlers.iOS.postMessage({
        //    type: 'sam-start',
        //    succ: "singleApplicationModeStartOnSuccess()",
        //    fail: "singleApplicationModeStartOnError()"
        //});
    }
}

function singleApplicationModeExit(): void {
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.iOS) {
        //window.webkit.messageHandlers.iOS.postMessage({
        //    type: "sam-exit",
        //    succ: "singleApplicationModeExitOnSuccess()",
        //    fail: "singleApplicationModeExitOnError()"
        //});
    }
}

//----------------------------------------------------------------------------

class WelcomeUi {
    welcomePage: HTMLElement;
    //logo: HTMLElement;
    version: HTMLElement;
    welcomePanel: HTMLElement;
    language: HTMLElement;
    languageSelect: Dropdown

    constructor() {
        this.version = <div className="app-version">{version}</div>;
        //this.logo = <div className="logo-panel"><img className="logo" alt="Practique" draggable={false} src="practique-logo-white.png"/></div>;
        this.welcomePanel = <div className="choose-exam"/>;
        this.language = <div className="language-dropdown-panel" aria-label="Change language" style={{backgroundColor: '#00000030'}}/>;
        this.languageSelect = new Dropdown({
            name: 'answer',
            parent: this.language,
            disabled: false,
            menuIcon: faLanguage
        });
        this.welcomePage = <div className="welcome">
            {this.version}
            {/*this.logo*/}
            {this.welcomePanel}
            {this.language}
        </div>;
        //this.language.addEventListener('click', this.handleLanguage);
        this.language.addEventListener('dropdown-select',this.handleSelect)
    }

    init() {
        for (const language of getLanguages()) {
            console.debug(language);
            this.languageSelect.addOption(language);
        }
        this.languageSelect.selectByIndex(getCurrentLanguage());
    }

    destroy() {
        this.language.removeEventListener('dropdown-select', this.handleSelect);
    }

    //private readonly handleLanguage = async () => {
    //    await nextLanguage();
    //    location.reload(); // reload from cache;
    //}

    private readonly handleSelect = async (e: Event) => {
        console.debug('EVENT', e)
        if (e instanceof CustomEvent && typeof e.detail === 'number') {
            await setLanguage(e.detail);
            location.reload();
        }
    }
}

//----------------------------------------------------------------------------
// Create UI

const wi = new WelcomeUi;
const content = document.getElementById('content');
if (content) {
    if (content.children.length > 0) {
        removeChildren(content);
    }
    content.appendChild(wi.welcomePage);
}

//----------------------------------------------------------------------------
// Server API

const state: State = {
    //exams: new Map(),
    //structure: [],
    //order: [],
    candidateId: ''
}

//----------------------------------------------------------------------------

const imageStore = new StoreChunks(0x1000000, 0x1000000);

interface Credentials {
    username: string;
    password: string;
}

function isCredentials(cred: unknown): cred is Credentials {
    return isIndexed(cred) &&
        typeof cred.username === 'string' &&
        typeof cred.password === 'string';
}

async function login(choice: ChosenExam|null): Promise<RemoteExamItem[]> {
    wi.welcomePanel.innerHTML = `<p class="progress-subtext">Loading candidate credentials...</p>`;
    const savedCredentials = await dbGet('users', 'credentials');
    if (isCredentials(savedCredentials)) {
        const credentials = savedCredentials;
        state.candidateId = credentials.username;
        state.candidatePin = credentials.password;
        setCredentials({
            loginId: credentials.username,
            loginPwd: credentials.password,
        });
        if (choice && choice.kind === 'exam') {
            return [];
        }
        try {
            wi.welcomePanel.innerHTML = `<p class="progress-subtext">Fetching available exams...</p>`;
            return (await getExamList()).exams ?? [];
        } catch (err) {
            console.warn(String(err));
            return [];
        }
    }
    await dbPut('users', 'examCount', 0);
    let loginError = '';
    let examList: RemoteExamItem[] = [];
    removeChildren(wi.welcomePanel);
    const auth = new Auth(wi.welcomePanel);
    while (true) {
        try {
            removeChildren(wi.welcomePanel);
            const credentials = await auth.login({error: loginError});
            state.candidateId = credentials.username;
            state.candidatePin = credentials.password;
            loginError = '';
            setCredentials({
                loginId: credentials.username,
                loginPwd: credentials.password,
            });
            wi.welcomePanel.innerHTML = `<p class="progress-subtext">Fetching available exams...</p>`;
            const {exams} = await getExamList();
            examList = exams ?? [];
            auth.destroy();
            wi.welcomePanel.innerHTML = `<p class="progress-subtext">Saving candidate credentials...</p>`;
            await dbPut('users', 'credentials', {
                username: state.candidateId,
                password: state.candidatePin,
            });
            return examList;
        } catch (err) {
            if (err instanceof HttpError) {
                if (err.status == 403) {
                    loginError = translate('ERROR_USER_PASS');
                } else {
                    loginError = translate('ERROR_HTTP', {code: localise(err.status)});
                }
            } else {
                loginError = translate('ERROR_UNKNOWN', {message: String(err)});
            }
        }
    }
}

async function savedExam():  Promise<ChosenExam|null> {
    const savedExam = await dbGet('users', 'exam');
    if (isExamChoice(savedExam)) {
        if (savedExam.examId && savedExam.pin || (!savedExam.proctored)) {
            console.debug('RESUMING SAVED EXAM')
            //window.history.replaceState(null, '', window.location.pathname);
            return {
                kind: 'exam',
                examId: savedExam.examId,
                examPin: savedExam.pin,
                autoPin: true, // shouldn't matter at this point.
            };
        }
    }
    return null;
}

async function loadExams(remoteExamList: RemoteExamItem[]): Promise<ExamMap|undefined> {
    wi.welcomePanel.innerHTML = `<p class="progress-subtext">Loading exam list...</p>`;
    const localExamMap = await getLocalExams();
    wi.welcomePanel.innerHTML = `<p class="progress-subtext">Removing finished exams...</p>`;
    await deleteUnavailable(localExamMap, remoteExamList);
    wi.welcomePanel.innerHTML = `<p class="progress-subtext">Fetching exam list...</p>`;
    await updateExamPins(localExamMap, remoteExamList);
    wi.welcomePanel.innerHTML = `<p class="progress-subtext">Fetching new exams...</p>`;
    const fetchList = getFetchList(localExamMap, remoteExamList);
    if (fetchList.length > 0) {
        removeChildren(wi.welcomePanel);
        retry: while (true) {
            const progress = new Progress(wi.welcomePanel);
            try {
                await fetchExams(localExamMap, fetchList, progress);
                break retry;
            } catch(err) {
                console.error(String(err));
                const opt = await errorModal({
                    title: translate('ERROR_DOWNLOAD'),
                    message: String(err),
                    primary: {retry: translate('ALERT_RETRY')},
                    other: {ignore: translate('ALERT_IGNORE')},
                });
                switch (opt) {
                    case 'retry':
                        break;
                    case 'ignore':
                        break retry;
                }
            } finally {
                progress.destroy();
            }
        }
    }
    return localExamMap; // await getLocalExams();
}

interface ExamChoice {
    examId: string;
    pin: string;
    proctored?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isExamChoice(exam: any): exam is ExamChoice {
    return exam && typeof exam.examId === 'string' && typeof exam.pin === 'string';
}

async function chooseExam(exams: ExamMap, examId: string|null, pin: string|null, proctored: boolean): Promise<ChosenExam|Logout> {
    const savedExamCount = await dbGet('users', 'examCount');
    if ((typeof savedExamCount !== 'number' || savedExamCount === 0) && !state.badPin) {
        const savedChoice = await dbGet('users', 'choice');
        if (typeof savedChoice == 'string' && savedChoice) {
            console.debug('SAVED CHOICE', savedChoice);
            const exam = exams.get(savedChoice);
            if (exam && exam.pin && (!exam.proctored || proctored)) {
                return {
                    kind: 'exam',
                    examId: savedChoice,
                    examPin: exam.pin,
                    autoPin: true,
                };
            }
        }
    }

    await dbClearSelect(); // new exam clear old just in case

    if (examId && pin) {
        console.debug('PIN', pin, "EXAM", examId);
        if (exams.has(examId)) {
            return {
                kind: 'exam',
                examId,
                examPin: pin,
                autoPin: true,
            };
        }
    } else {
        if ((typeof savedExamCount !== 'number' || savedExamCount === 0) && !state.badPin) {
            if (exams.size === 1) {
                const exam = exams.entries().next().value;
                if (exam) {
                    const [key, {pin, proctored: examProctored}] = exam;
                    if (pin && (!examProctored || proctored)) {
                        return {
                            kind: 'exam',
                            examId: key,
                            examPin: pin,
                            autoPin: true,
                        }
                    }
                }
            }
        }
    }

    let serverVersion = undefined
    try {
        serverVersion = await getText('/status?format=version');
        await dbPut('users', 'serverVersion', serverVersion);
    } catch (err) {
        console.error(String(err));
        try {
            const savedVersion = await dbGet('users', 'serverVersion');
            if (typeof savedVersion === 'string') {
                serverVersion = savedVersion;
            }
        } catch (err2) {
            console.error(String(err2));
        }
    }
    console.info('SERVER_VERSION', serverVersion);
    removeChildren(wi.welcomePanel);
    const assignmentViewer = new AssignmentViewer({
        parent: wi.welcomePanel,
        badPin: state.badPin ?? '',
        //badCid: state.badCid === true,
        examPin: state.examPin,
        autoPin: state.autoPin,
        candidateId: state.candidateId,
        exams: exams,
        chosenExamId: state.chosenExamId,
        manualStart: (serverVersion !== undefined) && /^8.0/.test(serverVersion),
        proctored,
    });
    const choice = await assignmentViewer.getExamChoice();
    assignmentViewer.destroy();
    return choice;
}

//function isServerConfig(x: unknown): x is {config: {version: string}} {
//    return isIndexed(x) && isIndexed(x.config) && typeof x.config.version === 'string';
//}

interface SavedManifest {
    manifest: PractiqueNet.ExamJson;
    structure: Structure[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isSavedManifest(savedManifest: any): savedManifest is SavedManifest {
    return savedManifest && typeof savedManifest.manifest === 'object' && typeof savedManifest.structure === 'object';
}

async function prepareExam(exams: ExamMap, imageStore: StoreChunks): Promise<{manifest?: PractiqueNet.ExamJson, structure?: Structure[]}> {
    wi.welcomePanel.innerHTML = `<p class="progress-subtext">Loading exam details...</p>`;
    const savedManifest = await dbGet('users', 'manifest');
    if (isSavedManifest(savedManifest)) {
        //console.debug('SAVED MANIFEST', savedManifest);
        return savedManifest;
    }

    console.debug('PREPARE EXAM');
    if (state.examPin == null) {
        throw 'EXAM_PIN is null';
    }
    if (state.chosenExamId == null) {
        throw 'CHOSEN_EXAM_ID is null';
    }
    if (state.candidateId == null) {
        throw 'CID is null';
    }
    console.debug('GET EXAM', exams, state.chosenExamId);
    const examIndex = exams.get(state.chosenExamId);
    if (examIndex == null) {
        throw 'EXAM_INDEX is null'
    }
    console.debug('GOT EXAM', state.chosenExamId);
    removeChildren(wi.welcomePanel);
    const progress = new Progress(wi.welcomePanel);
    try {
        const manifestAndStructure =  await imageStore.unpackExam(
            state.examPin,
            state.chosenExamId,
            state.candidateId,
            examIndex.size,
            examIndex.full_title,
            progress
        );
        wi.welcomePanel.innerHTML = `<p class="progress-subtext">Saving current exam...</p>`;
        await dbPut('users', 'exam', {examId: state.chosenExamId, pin: state.examPin, proctored: examIndex.proctored ?? false});
        wi.welcomePanel.innerHTML = `<p class="progress-subtext">Saving exam details...</p>`;
        await dbPut('users', 'manifest', manifestAndStructure);
        return {};
    } finally {
        progress.destroy();
    }
}

// stay in exam until we are sure answers are uploaded
async function exam(exams: ExamMap, meta: PractiqueNet.ExamJson, structure: Structure[], imageStore: StoreChunks): Promise<void> {
    console.debug('EXAM LAUNCHER', state.candidateId, meta.exam);
    try {
        singleApplicationModeStart();
        if (content && meta.exam.answer_aes_key && state.candidateId && state.chosenExamId) {
            console.debug('LAUNCH EXAM');
            const exam = exams.get(state.chosenExamId);
            meta.exam.demo ||= exam?.demo;
            const context = {
                content,
                structure,
                imageStore,
                candidateId: state.candidateId,
                candidateDetails: meta.candidateDetails,
                factorDetails: meta.factorDetails,
                component: exam?.component,
                meta: meta.exam,
                variantNames: [...(meta.variantDefault ? [meta.variantDefault] : []), ...(meta.variantNames ?? [])],
                onDestroy: async () => {
                    if (state.candidateId) {
                        try {
                            await deleteExam(exams, state.chosenExamId, true);
                        } catch (err) {
                            console.warn(err);
                        }
                    }
                }
            };
            wi.welcomePanel.innerHTML = `<p class="progress-subtext">Fetching exam state...</p>`;
            const examState = await cleanUpOldExam(context);
            if ((examState.state === ExamState.Waiting || examState.state === ExamState.Stopped || examState.state === ExamState.Deleted) && examState.unsubmitted.length === 0) {
                wi.welcomePanel.innerHTML = `<p class="progress-subtext">Removing finished exam content...</p>`;
                await deleteCurrentExam();
                if (examState.state === ExamState.Waiting) {
                    return;
                } else {
                    await deleteExam(exams, state.chosenExamId, true);
                }
            }
            wi.welcomePanel.innerHTML = `<p class="progress-subtext">Starting exam viewer...</p>`;
            if (!(examState.state === ExamState.Waiting || examState.state === ExamState.Stopped || examState.state === ExamState.Deleted)) {
                removeChildren(wi.welcomePanel);
                await runExam(examState, context);
            }
        }
    } finally {
        state.chosenExamId = undefined;
        state.examPin = undefined;
        singleApplicationModeExit();
        if (content != null) {
            removeChildren(content);
            content.appendChild(wi.welcomePage);
        }
    }
}

//const unsaved = false;

//window.onbeforeunload = (e: BeforeUnloadEvent): string|void => {
//    if (unsaved) {
//        e.preventDefault();
//        e.returnValue = 'Logout to ensure all answers have been sent to the server.';
//        return 'Logout to ensure all answers have been sent to the server.';
//    } else {
//        delete e['returnValue'];
//    }
//}

/*
interface Proctor {
    proctor: 'examity';
    url: string;
    UserName: string;
}
*/

// eslint-disable-next-line @typescript-eslint/no-explicit-any
/*function isProctor(proctor: any): proctor is Proctor {
    return proctor &&
        (typeof proctor.proctor === 'string') &&
        (typeof proctor.url === 'string') &&
        (typeof proctor.UserName === 'string');
}*/

async function logFreeStorage(): Promise<void> {
    // eslint-disable-next-line compat/compat
    const estimate = await navigator?.storage?.estimate?.();
    if (estimate && estimate.quota && estimate.usage) {
        console.info(`AVAILABLE_DISKSPACE ${Math.floor(estimate.usage / 1048576)} / ${Math.floor(estimate.quota / 1048576)} MB`);
    }
}

(async () => {
    await initTranslation();
    const latest = await getText('version.txt');
    if (version.trim() !== latest.trim()) {
        wi.version.style.backgroundColor = 'darkred';
        wi.version.innerHTML = translate('NEWER_VERSION_AVAILABLE', {version, latest});
    }
})();

window.onpageshow = () => {
    if (window.SafeExamBrowser != null && (window.SafeExamBrowser.security.browserExamKey == ':' || window.SafeExamBrowser.security.configKey == ':')) {
        window.SafeExamBrowser.security.updateKeys(main);
    } else {
        main();
    }
}

async function main(): Promise<void> {
    try {
        console.info('P4B VERSION', version);
        logFreeStorage();
        await initTranslation();
        wi.init()
        const deviceId = await dbGet('session', 'device-id');
        if (typeof deviceId == 'string' && deviceId) {
            setUUID({uuid: deviceId});
        } else {
            const uuid = genUuid();
            await dbPut('session', 'device-id', uuid);
            setUUID({uuid});
        }
        const urlParams = new URLSearchParams(window.location.search);
        console.debug('PARAMS', Array.from(urlParams.entries()));
        if (urlParams.get('logout') != null) {
            //await simpleModal({
            //    message: translate('LOGOUT_MESSAGE'),
            //    positive: {login: translate('LOGIN_AGAIN')},
            //});
            window.location.href = window.location.href.split("?")[0];
            return;
        } else {
            const choice = await savedExam();
            const remoteExamList = await login(choice);
            if (choice && choice.kind === 'exam') {
                const examMap = await getLocalExams();
                state.chosenExamId = choice.examId;
                state.examPin = choice.examPin;
                state.badPin = ''; //false;
                //state.badCid = false;
                try {
                    const {manifest, structure} = await prepareExam(examMap, imageStore);
                    if (manifest && structure) {
                        await exam(examMap, manifest, structure, imageStore);
                        if (examMap.size === 0) {
                            state.examPin = undefined;
                            state.candidateId = '';
                            state.candidatePin = undefined;
                            clearCredentials();
                            await dbClear('users');
                            window.location.search = 'logout';
                            return;
                        }
                    }
                } catch (err) {
                    console.error(err);
                    if (err instanceof DecryptionError) {
                        state.badPin = translate('ERROR_PIN'); //true;
                    } else {
                        state.badPin = (err instanceof Error) ? err.message : String(err);
                    }
                    /*
                    if (err === 'probably bad pin') {
                        state.badPin = translate('ERROR_PIN'); //true;
                    //} else if (err === 'candidate not found') {
                    //    state.badCid = true;
                    } else {
                        console.error(err);
                        await alertModal(String(err));
                        state.examPin = undefined;
                        state.candidateId = '';
                        state.candidatePin = undefined;
                        clearCredentials();
                        await dbClear('users');
                        window.location.search = 'logout';
                        return;
                    }
                    */
                }
            } else {
                const examMap = await loadExams(remoteExamList);
                console.debug('EXAMS', examMap);
                if (examMap) {
                    loop: while (true) {
                        const choice = await chooseExam(examMap, urlParams.get('exam'), urlParams.get('pin'), urlParams.has('proctored'));
                        console.debug('GOT CHOICE');
                        switch (choice.kind) {
                            case 'exam':
                                state.chosenExamId = choice.examId;
                                state.examPin = choice.examPin;
                                state.autoPin = choice.autoPin;
                                state.badPin = ''; //false;
                                //state.badCid = false;
                                try {
                                    const {manifest, structure} = await prepareExam(examMap, imageStore);
                                    if (manifest && structure) {
                                        await exam(examMap, manifest, structure, imageStore);
                                        if (examMap.size === 0) {
                                            state.examPin = undefined;
                                            state.candidateId = '';
                                            state.candidatePin = undefined;
                                            clearCredentials();
                                            await dbClear('users');
                                            window.location.search = 'logout';
                                            return;
                                        }
                                    }
                                    break loop;
                                } catch (err) {
                                    console.error(err);
                                    if (err instanceof DecryptionError) {
                                        state.badPin = translate('ERROR_PIN'); //true;
                                    } else {
                                        state.badPin = (err instanceof Error) ? err.message : String(err);
                                    }
                                    /*
                                        if (err instanceof Error) {
                                        state.badPin = err.message;
                                    } else if (err === 'candidate not found') {
                                        //state.badCid = true;
                                        state.badPin = translate('ERROR_PIN');
                                    } else {
                                        //state.badPin = String(err);
                                        await alertModal(String(err));
                                        state.examPin = undefined;
                                        state.candidateId = '';
                                        state.candidatePin = undefined;
                                        clearCredentials();
                                        await dbClear('users');
                                        window.location.search = 'logout';
                                        return;
                                        //break loop;
                                    }
                                    */
                                }
                                break;
                            case 'logout':
                                state.examPin = undefined;
                                state.candidateId = '';
                                state.candidatePin = undefined;
                                clearCredentials();
                                await dbClearLogin();
                                window.location.search = 'logout';
                                return;
                                //break loop;
                        }
                    }
                }
            }
        }
        window.location.reload();
    } catch (err) {
        console.error(String(err));
        const opt = await errorModal({
            message: String(err),
            primary: {retry: translate('ALERT_RETRY')},
            other: {quit: translate('ALERT_QUIT')},
        });
        if (opt === 'quit') {
            state.examPin = undefined;
            state.candidateId = '';
            state.candidatePin = undefined;
            try {
                clearCredentials();
                await dbClearLogin();
            } catch (err) {
                console.error(`logout: ${String(err)}`);
            }
            window.location.search = 'logout';
            return;
        }
        window.location.reload();
    }
};

