import Awesomplete from 'awesomplete';
import { mkNode, trim, scrollRangeIntoView, escapeRegExp, removeNode, safeJsonParse, newUniqueName } from '@p4b/utils';
import { LocalData } from '@p4b/exam-service';
import { Question, QuestionContext, QuestionManifest, QuestionBase, Expr, registerAnswerType, QuestionArgs} from '@p4b/question-base';
import { Lightbox } from '@p4b/lightbox';
import { configDngrTextBox, configSafeTextBox } from '@p4b/exam-accessibility';


interface Prescription {
    drug: string;
    dose: string;
    dose_unit: string;
    route: string;
    frequency: string;
    added_medication: string;
    added_medication_dose: string;
    added_medication_unit: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isPrescription(x: any): x is Prescription {
    return x && typeof x === 'object' &&
        typeof x.drug === 'string' &&
        typeof x.dose === 'string' &&
        typeof x.dose_unit === 'string' &&
        typeof x.route === 'string' &&
        typeof x.frequency === 'string' &&
        typeof x.added_medication === 'string' &&
        typeof x.added_medication_dose === 'string' &&
        typeof x.added_medication_unit === 'string';
}

interface Field {
    destroy(): void;
    isValid(): boolean;
    getValue(): string;
    setValue(value: string): void;
    disable(x: boolean): void;
    getTop(): number;
    getErrorHeight(): number;
    setErrorHeight(y: number): void;
}

class FieldShorttext {
    private answerLabel: HTMLDivElement;
    private answerItem: HTMLDivElement;
    protected input: HTMLInputElement;
    protected answerError: HTMLDivElement;

    public constructor(label: string, cname: string, parent: HTMLElement) {
        this.answerItem = mkNode('div', {
            className: cname
        });
        this.answerLabel = mkNode('div', {
            className: 'pvsa-label',
            parent: this.answerItem
        });
        this.answerLabel.innerHTML = label;
        this.input = mkNode('input', {
            className: 'pvsa-answer ' + configSafeTextBox,
            parent: this.answerItem,
            attrib: {
                type: 'text',
                autocomplete: newUniqueName(),
                autocorrect: "off",
                autocapitalize: "off",
                spellcheck: "false"
            }
        });
        this.answerError = mkNode('div', {
            className: 'pvsa-error config-danger100aa-text',
            parent: this.answerItem
        });
        parent.appendChild(this.answerItem);
    }

    public getValue(): string {
        return trim(this.input.value);
    }

    public setValue(x: string|null|undefined): void {
        this.input.value = x || '';
    }

    public focus(): void {
        this.input.focus();
    }

    public disable(x: boolean): void {
        this.input.disabled = x;
    }

    public getTop(): number {
        return this.answerItem.offsetTop;
    }

    public getErrorHeight(): number {
        return this.answerError.scrollHeight;
    }

    public setErrorHeight(y: number): void {
        this.answerError.style.height = y + 'px';
    }
}

function isValidOption(value: string, datalist: PractiqueNet.ExamJson.Definitions.OptionGroups): boolean {
    if (value === '') {
        return true;
    }
    for (let j = 0; j < datalist.length; ++j) {
        const r = new RegExp('^' + escapeRegExp(datalist[j].options[0]) + '\s*$');
        if (r.test(value)) {
            return true;
        }
    }
    return false;
}

function matches(value: string, datalist: PractiqueNet.ExamJson.Definitions.OptionGroups): string[] {
    const matches = [];
    if (value.match(/^[1-9lL]{1}/) || value.length > 1) {
        for (let j = 0; j < datalist.length; ++j) {
            const altlist = datalist[j].options;
            for (let i = 0; i < altlist.length; ++i) {
                const l = value.length;
                if (value.match(/^[1-9lL]{1}/) || l > 1) {
                    if (value.substring(0, l) === altlist[i].substring(0, l)) {
                        matches.push(altlist[0]);
                        break;
                    }
                }
            }
        }
    }
    return matches;
}

function isEmpty(obj: {[key: string]: unknown}): boolean {
    for (const key in obj) {
        if (obj[key]) {
            return false;
        }
    }
    return true;
}

class FieldAutotext extends FieldShorttext implements Field {
    private awe: Awesomplete;
    private optionGroups: PractiqueNet.ExamJson.Definitions.OptionGroups;
    private valid: boolean;

    public constructor(label: string, cname: string, parent: HTMLElement, datalist: PractiqueNet.ExamJson.Definitions.OptionGroups) {
        super(label, cname, parent);
        this.optionGroups = datalist;
        this.valid = true;
        const list = [];
        for (const {options: altlist} of datalist) {
            for (let i = 0; i < altlist.length; ++i) {
                list.push({label: altlist[i], value: altlist[0]});
            }
        }
        this.awe = new Awesomplete(this.input, {
            minChars: 1,
            maxItems: 100,
            list: list,
            filter: (text: string, input: string): boolean =>
                (input.match(/^[1-9lL]{1}/) || input.length > 1) &&
                trim(text.toLowerCase()).indexOf(trim(input.toLowerCase())) === 0
        })
        this.input.addEventListener('focus', this.handleFocus);
        this.input.addEventListener('input', this.handleInput);
        this.input.addEventListener('change', this.handleChange);
        this.input.addEventListener('awesomplete-selectcomplete', this.handleSelect);
    }

    public destroy(): void {
        this.input.removeEventListener('awesomplete-selectcomplete', this.handleSelect);
        this.input.removeEventListener('change', this.handleChange);
        this.input.removeEventListener('input', this.handleInput);
        this.input.removeEventListener('focus', this.handleFocus);
        this.awe.destroy();
    }

    public isValid(): boolean {
        return this.valid;
    }

    private validate(): void {
        if (isValidOption(this.input.value, this.optionGroups)) {
            this.input.className = 'pvsa-answer ' + configSafeTextBox;
            this.answerError.innerHTML = '';
            this.valid = true;
        } else {
            this.input.className = 'pvsa-answer ' + configDngrTextBox;
            this.answerError.innerHTML = 'This is not a possible answer';
            this.valid = false;
            scrollRangeIntoView(this.answerError, this.answerError, true);
        }
    }

    private complete(): void {
        const ms = matches(this.input.value, this.optionGroups);
        if (ms.length == 1) {
            this.input.value = ms[0];
        }
        this.validate();
    }

    private readonly handleFocus = (ev: Event): void => {
        if (ev.target instanceof HTMLInputElement) {
            if (ev.target.value.match(/^[1-9lL]{1}/) || ev.target.value.length > 1) {
                this.awe.evaluate();
            }
        }
    }

    private readonly handleInput = (): void => {
        this.validate();
    }

    private readonly handleChange = (): void => {
        this.complete();
    }

    private readonly handleSelect = (): void => {
        this.complete();
    }
}

const validNamePart = "[A-Za-z]{1}[A-Za-z0-9]*(?:'s|s')?";
const validName = validNamePart + '(?:(?:\\s+|-)' + validNamePart + ')*';
const validPercent = '\\s+(?:0\.[0-9]{0,4}[1-9]{1}|[1-9]{1}[0-9]*(?:\\.[0-9]{0,3}[1-9]{1})?)%';
const validRatioA = '\\s+[1-9]{1}[0-9]*:[1-9]{1}[0-9]*';
const validRatioB = '\\s+[1-9]{1}[0-9]*\\/[1-9]{1}[0-9]*';
const validPercentOrRatioA = '(?:' + validPercent + '|' + validRatioA + ')?';
const validPercentOrRatioB = '(?:' + validPercent + '|' + validRatioB + ')?';
const validMedicationA = validName + validPercentOrRatioA + '(?:\\s+' + validName + ')?';
const validMedicationB = validName + validPercentOrRatioB + '(?:\\s+' + validName + ')?';
const validMedications = '^\\s*(?:|' +
        validMedicationB + '|' +
        validMedicationA + '(?:\\s+\\/\\s+' + validMedicationA + ')*)\\s*$';
const regexMedications = new RegExp(validMedications);
function isValidMedication(s: string): boolean {
    return regexMedications.test(s);
}

class FieldMedication extends FieldShorttext implements Field {
    private valid: boolean;

    public constructor(label: string, cname: string, parent: HTMLElement) {
        super(label, cname, parent);
        this.input.addEventListener('input', this.handleInput);
        this.valid = true;
    }

    public destroy(): void {
        this.input.removeEventListener('input', this.handleInput);
    }

    public isValid(): boolean {
        return this.valid;
    }

    private readonly handleInput = (): void => {
        if (isValidMedication(this.input.value)) {
            this.input.className = 'pvsa-answer ' + configSafeTextBox;
            this.answerError.innerHTML = '';
            this.valid = true;
        } else {
            this.input.className = 'pvsa-answer ' + configDngrTextBox;
            this.answerError.innerHTML = 'Examples of valid medication name formats: Aspirin, Co-amoxiclav, Lignocaine 1%, Hydrocortisone 0.25%, Adrenaline 1:10000, Co-codamol 8/500';
            this.valid = false;
            scrollRangeIntoView(this.answerError, this.answerError, true);
        }
    }
}

const validDose = /^(?:[1-9]{1}[0-9]*(?:\.[0-9]{0,1}[1-9]{1}|\/[1-9]{1}[0-9]*)?|0\.[0-9]{0,1}[1-9]{1})?\s*$/;
function isValidDose(n: string): boolean {
    return validDose.test(n);
}

class FieldDose extends FieldShorttext implements Field {
    private valid: boolean

    public constructor(label: string, cname: string, parent: HTMLElement) {
        super(label, cname, parent);
        this.input.addEventListener('input', this.handleInput);
        this.valid = true;
    }

    public destroy(): void {
        this.input.removeEventListener('input', this.handleInput);
    }

    public isValid(): boolean {
        return this.valid;
    }

    private readonly handleInput = (): void => {
        if (isValidDose(this.input.value)) {
            this.input.className = 'pvsa-answer ' + configSafeTextBox;
            this.answerError.innerHTML = '';
            this.valid = true;
        } else {
            this.input.className = 'pvsa-answer ' + configDngrTextBox;
            this.answerError.innerHTML = 'Examples of valid doses: 20, 1.25, 250/125';
            this. valid = false;
            scrollRangeIntoView(this.answerError, this.answerError, true);
        }
    }
}

/** Prescribing question UI */
export class QuestionPrescribing extends QuestionBase implements Question {
    private answerItem: HTMLDivElement;
    //private answerLabel: HTMLDivElement;
    private answerBlock: HTMLDivElement;

    private fieldMedication: FieldMedication;
    private fieldDose: Field;
    private fieldUnit: Field;
    private fieldRoute: Field;
    private fieldFrequency: Field;
    private fieldAddMed: Field;
    private fieldAddDose: Field;
    private fieldAddUnit: Field;

    private value: string;
    private updateVisibility: () => void;

    public readonly visibilityExpression?: Expr;

    /** Construct LontextQuestion UI */
    public constructor(args: Omit<QuestionArgs, 'showFlag'> & {
        updateVisibility: () => void,
        visibilityExpression?: Expr,
        options: PractiqueNet.ExamJson.Definitions.PrescribingOptionGroups,
    }) {
        super({...args, showFlag: true, label: args.label || 'Please prescribe the most appropriate medication:'});
        const {updateVisibility, visibilityExpression, indent, options} = args;
        this.updateVisibility = updateVisibility;
        this.visibilityExpression = visibilityExpression;
        const indentRem = (1.6 * (indent ?? 0) + 1.6).toString();
        this.label.style.paddingLeft = `${indentRem}rem`;
        this.answerItem = mkNode('div', {className: 'answer-item', parent: this.column});
        //this.answerLabel = mkNode('div', {className: 'pvsa-top-label', parent: this.answerItem});
        this.answerBlock = mkNode('div', {className: 'answer-block', parent: this.answerItem});
        //this.answerLabel.appendChild(this.label);
        this.fieldMedication = new FieldMedication(
            'Medication', 'pvsa-item', this.answerBlock
        );
        const dosePair = mkNode('div', {className: 'pvsa-pair', parent: this.answerBlock});
        this.fieldDose = new FieldDose(
            'Dose', 'pvsa-small-item', dosePair);
        this.fieldUnit = new FieldAutotext(
            'Dose unit', 'pvsa-small-item', dosePair,
            options.dose
        );
        this.fieldRoute = new FieldAutotext(
            'Route', 'pvsa-item', this.answerBlock,
            options.route
        );
        this.fieldFrequency = new FieldAutotext(
            'Frequency <small>(or duration for fluids)</small>',
            'pvsa-item', this.answerBlock,
            options.frequency
        );
        this.fieldAddMed = new FieldMedication(
            'Medication added <small>(For fluids, if appropriate)</small>',
            'pvsa-item', this.answerBlock
        );
        const addPair = mkNode('div', {className: 'pvsa-pair', parent: this.answerBlock});
        this.fieldAddDose = new FieldDose(
            'Dose of medication added <small>(For fluids, if appropriate)</small>',
            'pvsa-small-item', addPair
        );
        this.fieldAddUnit = new FieldAutotext(
            'Dose unit of medication added <small>(For fluids, if appropriate)</small>',
            'pvsa-small-item', addPair,
            options.dose
        )
        this.value = '';
        addEventListener('resize', this.handleResize);
    }

    public updateDisable(): void {
        super.updateDisable();
        const disabled = this.isDisabled();
        this.fieldMedication.disable(disabled);
        this.fieldDose.disable(disabled);
        this.fieldUnit.disable(disabled);
        this.fieldRoute.disable(disabled);
        this.fieldFrequency.disable(disabled);
        this.fieldAddMed.disable(disabled);
        this.fieldAddDose.disable(disabled);
        this.fieldAddUnit.disable(disabled);
    }

    /** Free the resources used by LongtextQuestion */
    public destroy(): void {
        removeNode(this.answerItem);
        removeEventListener('resize', this.handleResize);
        this.answerBlock.removeEventListener('input', this.handleInput);
        this.answerBlock.removeEventListener('awesomplete-selectcomplete', this.handleSelect);
        this.answerBlock.removeEventListener('change', this.handleChange);
        this.fieldMedication.destroy();
        this.fieldDose.destroy();
        this.fieldUnit.destroy();
        this.fieldRoute.destroy();
        this.fieldFrequency.destroy();
        this.fieldAddMed.destroy();
        this.fieldAddDose.destroy();
        this.fieldAddUnit.destroy();
        //removeChildren(this.answerLabel);
        super.destroy();
    }

    public focus(): void {
        scrollRangeIntoView(this.answerItem, this.answerItem, true);
        this.fieldMedication.focus();
    }

    /** Load any stored answer */
    public loadAnswer(response?: LocalData): void {
        try {
            if (response && typeof response.answer === 'string') {
                const value = safeJsonParse(response.answer);
                if (isPrescription(value)) {
                    this.fieldMedication.setValue(value.drug);
                    this.fieldDose.setValue(value.dose);
                    this.fieldUnit.setValue(value.dose_unit);
                    this.fieldRoute.setValue(value.route);
                    this.fieldFrequency.setValue(value.frequency);
                    this.fieldAddMed.setValue(value.added_medication);
                    this.fieldAddDose.setValue(value.added_medication_dose);
                    this.fieldAddUnit.setValue(value.added_medication_unit);
                }
            }
            this.updateVisibility();
        } catch(e) {
            console.error(String(e));
        }
    }

    public loadingComplete(): void {
        super.loadingComplete();
        this.value = this.getString();
        this.answerBlock.addEventListener('change', this.handleChange);
        this.answerBlock.addEventListener('awesomplete-selectcomplete', this.handleSelect);
        this.answerBlock.addEventListener('input', this.handleInput);
    }

    public getValue(): string {
        return this.value;
    }

    private getString(): string {
        const prescription = {
            "drug": this.fieldMedication.getValue(),
            "dose": this.fieldDose.getValue(),
            "dose_unit": this.fieldUnit.getValue(),
            "route": this.fieldRoute.getValue(),
            "frequency": this.fieldFrequency.getValue(),
            "added_medication": this.fieldAddMed.getValue(),
            "added_medication_dose": this.fieldAddDose.getValue(),
            "added_medication_unit": this.fieldAddUnit.getValue()
        };
        return (isEmpty(prescription)) ? '' : JSON.stringify(prescription);
    }

    //public getAnswer(): AnswerKey & AnswerValue {
    //    return {qno: this.qno, ano: this.ano, answer: this.value || null}
    //}

    private async change(): Promise<void> {
        this.validate();
        if (this.context.responses.getValid()) {
            const answer = this.getString();
            if (answer != this.value) {
                this.value = answer;
                try {
                    await this.context.responses.saveAnswer({qno: this.qno, ano: this.ano}, {answer: this.value || null});
                } catch(e) {
                    console.error(String(e));
                } finally {
                    this.updateVisibility();
                }
            }
        }
    }

    private validate(): void {
        this.context.responses.setValid(
            this.fieldMedication.isValid() &&
            this.fieldDose.isValid() &&
            this.fieldUnit.isValid() &&
            this.fieldRoute.isValid() &&
            this.fieldFrequency.isValid() &&
            this.fieldAddMed.isValid() &&
            this.fieldAddDose.isValid() &&
            this.fieldAddUnit.isValid()
        );
        setImmediate(this.handleResize);
    }

    private resize(): void {
        const top = this.answerItem.offsetParent?.scrollTop;
        this.fieldMedication.setErrorHeight(0);
        this.fieldDose.setErrorHeight(0);
        this.fieldUnit.setErrorHeight(0);
        this.fieldRoute.setErrorHeight(0);
        this.fieldFrequency.setErrorHeight(0);
        this.fieldAddMed.setErrorHeight(0);
        this.fieldAddDose.setErrorHeight(0);
        this.fieldAddUnit.setErrorHeight(0);
        const drugTop = this.fieldMedication.getTop();
        const doseTop = this.fieldDose.getTop();
        const unitTop = this.fieldUnit.getTop();
        const routeTop = this.fieldRoute.getTop();
        const frequencyTop = this.fieldFrequency.getTop();
        const addMedTop = this.fieldAddMed.getTop();
        const addDoseTop = this.fieldAddDose.getTop();
        const addUnitTop = this.fieldAddUnit.getTop();
        const maxHeight = new Map();
        maxHeight.set(drugTop, 0);
        maxHeight.set(doseTop, 0);
        maxHeight.set(unitTop, 0);
        maxHeight.set(routeTop, 0);
        maxHeight.set(frequencyTop, 0);
        maxHeight.set(addMedTop, 0);
        maxHeight.set(addDoseTop, 0);
        maxHeight.set(addUnitTop, 0);
        maxHeight.set(drugTop, Math.max(maxHeight.get(drugTop), this.fieldMedication.getErrorHeight()));
        maxHeight.set(doseTop, Math.max(maxHeight.get(doseTop), this.fieldDose.getErrorHeight()));
        maxHeight.set(unitTop, Math.max(maxHeight.get(unitTop), this.fieldUnit.getErrorHeight()));
        maxHeight.set(routeTop, Math.max(maxHeight.get(routeTop), this.fieldRoute.getErrorHeight()));
        maxHeight.set(frequencyTop, Math.max(maxHeight.get(frequencyTop), this.fieldFrequency.getErrorHeight()));
        maxHeight.set(addMedTop, Math.max(maxHeight.get(addMedTop), this.fieldAddMed.getErrorHeight()));
        maxHeight.set(addDoseTop, Math.max(maxHeight.get(addDoseTop), this.fieldAddDose.getErrorHeight()));
        maxHeight.set(addUnitTop, Math.max(maxHeight.get(addUnitTop), this.fieldAddUnit.getErrorHeight()));
        this.fieldMedication.setErrorHeight(maxHeight.get(drugTop));
        this.fieldDose.setErrorHeight(maxHeight.get(doseTop));
        this.fieldUnit.setErrorHeight(maxHeight.get(unitTop));
        this.fieldRoute.setErrorHeight(maxHeight.get(routeTop));
        this.fieldFrequency.setErrorHeight(maxHeight.get(frequencyTop));
        this.fieldAddMed.setErrorHeight(maxHeight.get(addMedTop));
        this.fieldAddDose.setErrorHeight(maxHeight.get(addDoseTop));
        this.fieldAddUnit.setErrorHeight(maxHeight.get(addUnitTop));
        if (this.answerItem.offsetParent && top) {
            this.answerItem.offsetParent.scrollTop = top;
        }
    }

    private handleChange = (): void => {
        this.change();
    };

    private handleSelect = (): void => {
        this.change()
    };

    private handleInput = (): void => {
        this.validate();
    };

    private handleResize = (): void => {
        this.resize();
    };
}

registerAnswerType({
    name: 'Prescribing',
    isThis: (answer: PractiqueNet.ExamJson.Definitions.Answer): boolean => {
        return answer.type.toLowerCase() === 'pvsa';
    },
    makeAnswer: (
        qno: number,
        context: QuestionContext,
        updateVisibility: () => void,
        question: QuestionManifest,
        answer: PractiqueNet.ExamJson.Definitions.Answer,
        frag: DocumentFragment,
        ano: number,
        lightbox: Lightbox,
        isRemoteShowHide: boolean,
        isOSCE: boolean,
    ) => new QuestionPrescribing({
        updateVisibility,
        context,
        qno,
        ano,
        backendQid: question.manifest.backend_id,
        backendAid: answer.backend_id,
        showNumber: question.manifest.answers.length > 1,
        label: answer.label,
        frag,
        options: (answer.type === 'pvsa') ? answer.options : {
            dose: [],
            route: [],
            frequency: [],
        },
        lightbox,
        isRemoteShowHide,
        indent: answer.indent,
        visibilityExpression: answer.visible,
        notes: answer.notes,
        resources: question.answersResources[ano],
        mandatory: answer.mandatory,
        type: answer.type.toLowerCase(),
        isOSCE,
    })
});
