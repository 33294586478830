import {Progress} from '@p4b/utils-progress';
import {translate} from '@p4b/utils-lang';
import {safeJsonParse, wait} from '@p4b/utils';
import {version} from '@gen/version';


declare global {
    interface Window {
        SafeExamBrowser: {
            version: string;
            security: {
                configKey: string;
                browserExamKey: string;
                updateKeys(callback: () => void): void;
            }
        }
    }
}

const safeExamBrowser = window.SafeExamBrowser != null

//let admin = false;
let loginId = "";
let loginPwd = "";

export function setCredentials(args: {loginId: string; loginPwd: string}): void {
    //admin = false;
    loginId = args.loginId;
    loginPwd = args.loginPwd;
}

export function clearCredentials(): void {
    //admin = false;
    loginId = "";
    loginPwd = "";
}

let uuid = "";

export function setUUID(args: {uuid: string}): void {
    uuid = args.uuid;
}

export class HttpError extends Error {
    constructor(public readonly status: number, message: string) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
    }

    get name() {
        return 'HttpError';
    }
}

export class AbortError extends Error {
    constructor() {
        super('HTTP operation terminated');
        Object.setPrototypeOf(this, new.target.prototype);
    }

    get name() {
        return 'AbortError';
    }
}

export async function requestJson<T>(url: string, obj: T): Promise<{data?: unknown, req_timestamp?: number, rsp_timestamp?: number}> {
    return new Promise(async (succ, fail) => {
        try {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            xhr.onerror = (): void => {
                fail(new Error(translate('ERROR_NETWORK')));
            };
            xhr.ontimeout = (): void => {
                fail(new Error(translate('ERROR_CONNECTION_TIMEOUT')));
            };
            xhr.onload = () => {
                const rsp_timestamp = Date.now();
                if (xhr.status === 200) {
                    if (xhr.responseText) {
                        const data = safeJsonParse(xhr.responseText);
                        succ({data, req_timestamp, rsp_timestamp});
                    } else {
                        succ({data: null, req_timestamp, rsp_timestamp});
                    }
                } else {
                    fail(new HttpError(xhr.status, `${xhr.statusText} "${xhr.responseText}"`));
                }
            };
            xhr.onabort = () => {
                fail(new AbortError());
            };
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");
            if (safeExamBrowser) {
                xhr.setRequestHeader('X-SafeExamBrowser-RequestHash', window.SafeExamBrowser.security.browserExamKey);
                xhr.setRequestHeader('X-SafeExamBrowser-ConfigKeyHash', window.SafeExamBrowser.security.configKey);
            }
            if (loginId && loginPwd) {
                xhr.setRequestHeader('X-Risr-User', loginId);
                xhr.setRequestHeader('X-Risr-Pass', loginPwd);
            }
            if (uuid) {
                xhr.setRequestHeader('X-Risr-Device-Id', uuid);
            }
            xhr.setRequestHeader('X-Risr-Vers', version);
            xhr.responseType = 'text';
            //xhr.timeout = 300000;
            const req_timestamp = Date.now();
            xhr.send(JSON.stringify(obj));
        } catch (err) {
            fail(err);
        }
    });
}

export function getHead(url: string): Promise<string> {
    return new Promise((succ, fail): void => {
        try {
            const xhr = new XMLHttpRequest();
            xhr.open('HEAD', url, true);

            xhr.onerror = (): void => {
                fail(new Error(translate('ERROR_NETWORK')));
            };
            xhr.ontimeout = (): void => {
                fail(new Error(translate('ERROR_CONNECTION_TIMEOUT')));
            };
            xhr.onabort = () => {
                fail(new AbortError());
            };
            xhr.onload = (): void => {
                if (xhr.status === 200) {
                    succ(xhr.getResponseHeader('content-length') || '0');
                } else {
                    fail(new HttpError(xhr.status, `${xhr.statusText} "${xhr.responseText}"`));
                }
            };
            xhr.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");
            if (safeExamBrowser) {
                xhr.setRequestHeader('X-SafeExamBrowser-RequestHash', window.SafeExamBrowser.security.browserExamKey);
                xhr.setRequestHeader('X-SafeExamBrowser-ConfigKeyHash', window.SafeExamBrowser.security.configKey);
            }
            if (loginId && loginPwd) {
                xhr.setRequestHeader('X-Risr-User', loginId);
                xhr.setRequestHeader('X-Risr-Pass', loginPwd);
            }
            if (uuid) {
                xhr.setRequestHeader('X-Risr-Device-Id', uuid);
            }
            xhr.setRequestHeader('X-Risr-Vers', version);
            //xhr.timeout = 30000;
            xhr.send(null);
        } catch (err) {
            fail(err);
        }
    });
}

export function getText(url: string): Promise<string> {
    return new Promise((succ, fail): void => {
        try {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.onerror = (): void => {
                fail(new Error(translate('ERROR_NETWORK')));
            };
            xhr.ontimeout = (): void => {
                fail(new Error(translate('ERROR_CONNECTION_TIMEOUT')));
            };
            xhr.onabort = () => {
                fail(new AbortError());
            };
            xhr.onload = (): void => {
                if (xhr.status === 200) {
                    succ(xhr.responseText);
                } else {
                    fail(new HttpError(xhr.status, `${xhr.statusText} "${xhr.responseText}"`));
                }
            };
            xhr.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");
            if (safeExamBrowser) {
                xhr.setRequestHeader('X-SafeExamBrowser-RequestHash', window.SafeExamBrowser.security.browserExamKey);
                xhr.setRequestHeader('X-SafeExamBrowser-ConfigKeyHash', window.SafeExamBrowser.security.configKey);
            }
            if (loginId && loginPwd) {
                xhr.setRequestHeader('X-Risr-User', loginId);
                xhr.setRequestHeader('X-Risr-Pass', loginPwd);
            }
            if (uuid) {
                xhr.setRequestHeader('X-Risr-Device-Id', uuid);
            }
            xhr.setRequestHeader('X-Risr-Vers', version);
            xhr.responseType = 'text';
            //xhr.timeout = 30000;
            xhr.send(null);
        } catch (err) {
            fail(err);
        }
    });
}

export async function getJson(url: string, start?: number, end?: number): Promise<unknown> {
    return new Promise((succ, fail): void => {
        try {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.onerror = (): void => {
                fail(new Error(translate('ERROR_NETWORK')));
            };
            xhr.ontimeout = (): void => {
                fail(new Error(translate('ERROR_CONNECTION_TIMEOUT')));
            };
            xhr.onabort = () => {
                fail(new AbortError());
            };
            xhr.onload = (): void => {
                if (xhr.status === 200) {
                    succ(JSON.parse(xhr.responseText));
                } else {
                    fail(new HttpError(xhr.status, `${xhr.statusText} "${xhr.responseText}"`));
                }
            };
            if (start || end) {
                console.debug('range', start, '-', end);
                xhr.setRequestHeader('Range', 'bytes=' + start + '-' + end);
            }
            xhr.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");
            if (safeExamBrowser) {
                xhr.setRequestHeader('X-SafeExamBrowser-RequestHash', window.SafeExamBrowser.security.browserExamKey);
                xhr.setRequestHeader('X-SafeExamBrowser-ConfigKeyHash', window.SafeExamBrowser.security.configKey);
            }
            if (loginId && loginPwd) {
                xhr.setRequestHeader('X-Risr-User', loginId);
                xhr.setRequestHeader('X-Risr-Pass', loginPwd);
            }
            if (uuid) {
                xhr.setRequestHeader('X-Risr-Device-Id', uuid);
            }
            xhr.setRequestHeader('X-Risr-Vers', version);
            xhr.responseType = 'text';
            xhr.send(null);
        } catch (err) {
            fail(err);
        }
    });
}

export function httpDelete(url: string): Promise<void> {
    return new Promise((succ, fail): void => {
        try {
            const xhr = new XMLHttpRequest();
            xhr.open('DELETE', url, true);
            xhr.onerror = (): void => {
                fail(new Error(translate('ERROR_NETWORK')));
            };
            xhr.ontimeout = (): void => {
                fail(new Error(translate('ERROR_CONNECTION_TIMEOUT')));
            };
            xhr.onabort = () => {
                fail(new AbortError());
            };
            xhr.onload = (): void => {
                if (xhr.status === 200) {
                    succ();
                } else {
                    fail(new HttpError(xhr.status, `${xhr.statusText} "${xhr.responseText}"`));
                }
            };
            xhr.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");
            if (safeExamBrowser) {
                xhr.setRequestHeader('X-SafeExamBrowser-RequestHash', window.SafeExamBrowser.security.browserExamKey);
                xhr.setRequestHeader('X-SafeExamBrowser-ConfigKeyHash', window.SafeExamBrowser.security.configKey);
            }
            if (loginId && loginPwd) {
                xhr.setRequestHeader('X-Risr-User', loginId);
                xhr.setRequestHeader('X-Risr-Pass', loginPwd);
            }
            if (uuid) {
                xhr.setRequestHeader('X-Risr-Device-Id', uuid);
            }
            xhr.setRequestHeader('X-Risr-Vers', version);
            //xhr.timeout = 30000;
            xhr.send(null);
        } catch (err) {
            fail(err);
        }
    });
}

export interface ProgressUi {
    ui: {
        progressBox: HTMLDivElement;
        titleBox: HTMLDivElement;
        subtextBox: HTMLDivElement;
        title: Text;
        subtext: Text;
        spinner: HTMLDivElement;
        textBox: HTMLDivElement;
        text: Text;
    };
    currentSize?: number;
    totalSize?: number;
}

export async function rangeSupported(url: string): Promise<{ranged:boolean,size:number}> {
    return new Promise((succ, fail): void => {
        try {
            const xhr = new XMLHttpRequest();
            xhr.open('HEAD', url, true);
            xhr.onerror = (): void => {
                fail(new Error(translate('ERROR_NETWORK')));
            };
            xhr.ontimeout = (): void => {
                fail(new Error(translate('ERROR_CONNECTION_TIMEOUT')));
            };
            xhr.onabort = () => {
                fail(new AbortError());
            };
            xhr.onload = (): void => {
                if (xhr.status === 206) {
                    console.log('RANGE', xhr.getResponseHeader('content-range'));
                    console.log('MATCH', xhr.getResponseHeader('content-range')?.match(/\/([0-9]*)$/)?.[1]);
                    const ranged = true;
                    const size = parseInt(xhr.getResponseHeader('content-range')?.match(/\/([0-9]*)$/)?.[1] || '0');
                    succ({ranged, size});
                } else if (xhr.status === 200) {
                    const ranged = false;
                    const size = parseInt(xhr.getResponseHeader('content-length') || '0');
                    succ({ranged, size});
                } else {
                    fail(new HttpError(xhr.status, xhr.statusText));
                }
            }
            xhr.setRequestHeader('Range', 'bytes=0-1');
            xhr.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");
            if (safeExamBrowser) {
                xhr.setRequestHeader('X-SafeExamBrowser-RequestHash', window.SafeExamBrowser.security.browserExamKey);
                xhr.setRequestHeader('X-SafeExamBrowser-ConfigKeyHash', window.SafeExamBrowser.security.configKey);
            }
            if (loginId && loginPwd) {
                xhr.setRequestHeader('X-Risr-User', loginId);
                xhr.setRequestHeader('X-Risr-Pass', loginPwd);
            }
            if (uuid) {
                xhr.setRequestHeader('X-Risr-Device-Id', uuid);
            }
            xhr.setRequestHeader('X-Risr-Vers', version);
            xhr.responseType = 'arraybuffer';
            //xhr.timeout = 30000;
            xhr.send(null);
        } catch (err) {
            fail(err);
        }
    });
}

export function getArrayBuffer(url: string, progress: Progress, start?: number, end?: number): Promise<ArrayBuffer> {
    return new Promise((succ, fail): void => {
        try {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.onerror = (): void => {
                fail(new Error(translate('ERROR_NETWORK')));
            };
            xhr.ontimeout = (): void => {
                console.log('CHARS GOT:', xhr.response.byteLength);
                fail(new Error(translate('ERROR_CONNECTION_TIMEOUT')));
            };
            xhr.onabort = () => {
                fail(new AbortError());
            };
            xhr.onprogress = async (e): Promise<void> => {
                if (e.loaded != null) {
                    console.debug(`LENGTH_SO_FAR ${e.loaded}`);
                    await progress.show(e.loaded);
                }
            };
            xhr.onload = (): void => {
                if (xhr.status === 200 || xhr.status === 206) {
                    progress.save(xhr.response.byteLength);
                    if (start || end) {
                        console.debug('downloaded exam chunk, start: ', start, ' end: ', start + xhr.response.byteLength);
                    } else {
                        console.debug('DOWNLOADED');
                    }
                    succ(xhr.response);
                } else {
                    fail(new HttpError(xhr.status, xhr.statusText));
                }
            };
            if (start || end) {
                console.debug('range', start, '-', end);
                xhr.setRequestHeader('Range', 'bytes=' + start + '-' + end);
            }
            xhr.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");
            if (safeExamBrowser) {
                xhr.setRequestHeader('X-SafeExamBrowser-RequestHash', window.SafeExamBrowser.security.browserExamKey);
                xhr.setRequestHeader('X-SafeExamBrowser-ConfigKeyHash', window.SafeExamBrowser.security.configKey);
            }
            if (loginId && loginPwd) {
                xhr.setRequestHeader('X-Risr-User', loginId);
                xhr.setRequestHeader('X-Risr-Pass', loginPwd);
            }
            if (uuid) {
                xhr.setRequestHeader('X-Risr-Device-Id', uuid);
            }
            xhr.setRequestHeader('X-Risr-Vers', version);
            xhr.responseType = 'arraybuffer';
            //xhr.timeout = 30000;
            xhr.send(null);
        } catch (err) {
            fail(err);
        }
    });
}

export async function streamArrayBuffer({url, progress, start, end}: {url: string, progress: Progress, start: number, end: number}): Promise<ArrayBuffer> {
    // note: we don't catch errors from fetch here, as we want them to bubble up
    if (!window.fetch) {
        // fallback to old method
        return getArrayBuffer(url, progress, start, end);
    }
    const buffer = new Uint8Array(end - start + 1);
    const offset = start;
    while (start < end) {
        const seb = (safeExamBrowser) ? {
            'X-SafeExamBrowser-RequestHash': window.SafeExamBrowser.security.browserExamKey,
            'X-SafeExamBrowser-ConfigKeyHash': window.SafeExamBrowser.security.configKey
        } : {};
        const credentials = (loginId && loginPwd) ? {
            'X-Risr-User': loginId,
            'X-Risr-Pass': loginPwd
        } : {};
        const device = (uuid) ? {
            'X-Risr-Device-Id': uuid
        } : {};
        const range = (start || end) ? {
            'Range': `bytes=${start}-${end}`
        } : {};
        const response = await fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                'X-Risr-Vers': version,
                'Cache-Control': 'no-cache, no-store, max-age=0',
                ...seb,
                ...credentials,
                ...device,
                ...range
            } as HeadersInit
        });
        if (response.ok) {
            const originalStart = start;
            try {
                const reader = response.body?.getReader();
                if (reader) {
                    while (true) {
                        const {value, done} = await reader.read();
                        if (value) {
                            console.debug('GOT', value.byteLength, 'BYTES');
                            buffer.set(value, start - offset);
                            start += value.byteLength;
                            progress.save(value.byteLength);
                            await progress.show();
                        }
                        if (done) {
                            break;
                        }
                    }
                    return buffer.buffer; // transfer complete
                } else {
                    throw new Error('No reader available');
                }
            } catch (networkError) {
                if (start - originalStart > 0) {
                    // we got some data - can retry
                    console.warn('RETRYING', networkError);
                } else {
                    throw networkError;
                }
            }
        } else {
            throw new HttpError(response.status, response.statusText);
        }
    }
    return buffer.buffer;
}

export async function streamText({url, progress, start, end}: {url: string, progress: Progress, start: number, end: number}): Promise<string> {
    return new TextDecoder().decode(await streamArrayBuffer({url, progress, start, end}));
}

export async function streamJson({url, progress, start, end}: {url: string, progress: Progress, start: number, end: number}): Promise<unknown> {
    return safeJsonParse(await streamText({url, progress, start, end}));
}

const temporaryFailures = [500, 502, 503, 504, 507];

export async function retry<T>(fn: () => Promise<T>, attempts: number): Promise<T> {
    let delay = 100;
    while(true) {
        try {
            return await fn();
        } catch(err) {
            if (err instanceof HttpError && temporaryFailures.indexOf(err.status) > -1) {
                throw err;
            }
            if (--attempts <= 0) {
                throw err;
            }
            delay += Math.floor(delay * Math.random());
            console.warn(`Retrying after ${delay}ms due to:`, err);
            await wait(delay);
            delay *= 10;
        }
    }
}

