import { configPrimPress } from '@p4b/exam-accessibility';
import { Img } from '@p4b/image-base';
import { mkNode } from '@p4b/utils';
import { translate } from '@p4b/utils-lang';
import { ImageViewerElement } from '@p4b/image-viewer';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

/*function contains(r: DOMRect|undefined, p: {x: number, y: number}): boolean {
    return (r === undefined) ? false : r.left <= p.x && p.x <= r.right && r.top <= p.y && p.y <= r.bottom;
}*/

export interface Thumbnail {
    destroy: () => void;
    load: (buf: ArrayBuffer|null) => Promise<void>;
    select: (x: boolean) => void;
    hide: (hidden: boolean) => void;
    disable: (disable: boolean) => void;
    disableShowHide: (disable: boolean) => void;
    isDisabled: () => boolean;
    isHidden: () => boolean;
    contains: (n: Node) => boolean;
    open: (viewer: ImageViewerElement) => void;
    defferedLoad: (viewer: ImageViewerElement) => Promise<void>;
    getImage: () => Img;
    getStatus: () => boolean;
    setStatus: (release: boolean) => void;
    addObserver: (observer: ThumbnailObserver) => void;
}

export class ThumbnailBase {
    protected figure: HTMLDivElement;
    protected figcaption: HTMLDivElement;
    protected figinfo: HTMLDivElement;
    protected buttonBar?: HTMLDivElement;
    protected showButton?: HTMLButtonElement;
    protected hideButton?: HTMLButtonElement;
    public readonly image: Img;

    constructor(parent: Node, image: Img, releaseButton: boolean, subInitThumb: (figure: HTMLDivElement) => void) {
        //console.debug('DISTRIBUTION', image.distribution);
        this.image = image;
        this.figure =  mkNode('div', {
            className: 'figure config-user200-bg config-user400-bg-hover config-user000aaa-outline-focus',
            attrib: {tabindex: '0'}
        });
        this.figcaption = mkNode('div', {
            className: 'figcaption',
            parent: this.figure
        });
        subInitThumb(this.figure);
        this.figinfo = mkNode('div', {
            className: 'figinfo',
            parent: this.figure
        });
        this.figcaption.innerHTML = image.caption || '&nbsp;'
        this.figinfo.innerHTML = (image.frameCount > 1) ? ('[' + image.frameCount + ' slices]') : '&nbsp;';
        if (releaseButton && image.distribution === 'restricted') {
            this.buttonBar = mkNode('div', {className:'figure-buttons', parent: this.figure});
            this.showButton = mkNode('button', {className: 'figure-button ' + configPrimPress, style: {textAlign: 'center'}, parent: this.buttonBar, attrib: {'aria-pressed': 'false'}, children: [
                mkNode('span', {className: 'icon-left', children: [
                    mkNode('icon', {icon: faEye}),
                ]}),
                mkNode('text', {text: translate('RESOURCE_RESTRICTED_SHOW')}),
            ]});
            this.showButton.addEventListener('click', this.handleShow);
            this.hideButton = mkNode('button', {className: 'figure-button ' + configPrimPress, style: {textAlign: 'center'}, parent: this.buttonBar, attrib: {'aria-pressed': 'true'}, children: [
                mkNode('span', {className: 'icon-left', children: [
                    mkNode('icon', {icon: faEyeSlash}),
                ]}),
                mkNode('text', {text: translate('RESOURCE_RESTRICTED_HIDE')}),
            ]});
            this.hideButton.addEventListener('click', this.handleHide);
        }
        parent.appendChild(this.figure);
        return;
    }

    public select(x: boolean): void {
        this.figure.className = x ? 'figure-open' : 'figure';
    }

    public hide(hidden: boolean): void {
        switch (this.image.distribution) {
            case 'restricted':
                this.figure.hidden = hidden;
                break;
            default:
                this.figure.hidden = false;
                break;
        }
    }

    public disableShowHide(disabled: boolean): void {
        this.showButton && (this.showButton.disabled = disabled);
        this.hideButton && (this.hideButton.disabled = disabled);
    }

    public disable(disabled: boolean): void {
        this.figure.setAttribute('aria-disabled', String(disabled));
        this.figure.setAttribute('tabindex', disabled ? '-1' : '0');
    }

    public isDisabled(): boolean {
        return this.figure.getAttribute('aria-disabled') === 'true';

    }

    public isHidden(): boolean {
        if (this.showButton) {
            return this.showButton.getAttribute('aria-pressed') !== 'true'
        } else {
            return this.figure.hidden;
        }
    }

    public contains(p: Node): boolean {
        return this.figure.contains(p) &&
            !this.showButton?.contains(p) &&
            !this.hideButton?.contains(p);
    }

    public destroy() : void {
        this.showButton?.removeEventListener('click', this.handleShow);
        this.hideButton?.removeEventListener('click', this.handleHide);
    }

    public getImage(): Img {
        return this.image;
    }

    public getStatus(): boolean {
        if (this.showButton) {
            return this.showButton?.getAttribute('aria-pressed') === 'true';
        } else {
            return !this.figure.hidden;
        }
    }

    private observer?: ThumbnailObserver;

    public addObserver(observer: ThumbnailObserver): void {
        this.observer = observer;
    }

    public setStatus(release = !(this.showButton?.getAttribute('aria-pressed') === 'true')): void {
        if (this.showButton && this.hideButton) {
            if (release) {
                this.showButton?.setAttribute('aria-pressed', 'true');
                this.hideButton?.setAttribute('aria-pressed', 'false');
            } else {
                this.showButton?.setAttribute('aria-pressed', 'false');
                this.hideButton?.setAttribute('aria-pressed', 'true');
            }
        }
    }

    private readonly handleShow = () => {
        this.setStatus(true);
        this.observer?.handleSendStatus({id: this.image.id, released: true});
    }

    private readonly handleHide = () => {
        this.setStatus(false);
        this.observer?.handleSendStatus({id: this.image.id, released: false});
    }
}

export interface ThumbnailObserver {
    handleSendStatus: (status: {id: string, released: boolean}) => void;
}
