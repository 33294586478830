import { dbPut, dbGet } from "@p4b/utils-db";

interface Language {
    LOCALE: string,
    LOGIN_INSTRUCTIONS: string,
    LOGIN_CANDIDATE_ID_LABEL: string,
    LOGIN_PASSWORD_LABEL: string,
    LOGIN_BUTTON: string,

    FREE_SPACE: (args: {used: string, quota: string}) => string,

    CHOOSE_INSTRUCTIONS: (args: {cid: string}) => string,
    CHOOSE_PROCTORED_EXAMITY: string,
    CHOOSE_PROCTORED_GENERIC: (args: {proctorName: string}) => string,
    CHOOSE_PROCTORED_SUPPORT: string,
    CHOOSE_NO_EXAMS: string,
    CHOOSE_SELECT_EXAM: string,
    CHOOSE_AUTO_START: string,
    CHOOSE_AUTO_SUPPORT: string,
    CHOOSE_MANUAL_START: string,
    CHOOSE_MANUAL_SUPPORT: string,
    CHOOSE_PRESS_START: string;
    CHOOSE_EXAM_LABEL: string,
    CHOOSE_MANUAL_PIN_LABEL: string,
    CHOOSE_AUTO_PIN_LABEL: string,
    CHOOSE_EXAM_BUTTON: string,
    CHOOSE_LOGOUT_BUTTON: string,
    CHOOSE_PROCTOR_BUTTON: string,
    CHOOSE_EXAM_HINT: string,
    CHOOSE_WAITING_TO_START: string,
    CHOOSE_ALREADY_STARTED: string,
    CHOOSE_OFFLINE_BUTTON: string,

    ERROR_NETWORK: string,
    ERROR_USER_PASS: string,
    ERROR_CONNECTION_TIMEOUT: string,
    ERROR_HTTP: (args: {code: string}) => string,
    ERROR_PROXY: string,
    ERROR_UNKNOWN: (args: {message: string}) => string,
    ERROR_STORAGE: string,
    ERROR_PIN: string,
    ERROR_CANDIDATE_NOT_FOUND: string,
    ERROR_RESEND: (args: {err: string}) => string,
    ERROR_DOWNLOAD: string,

    DOWNLOAD_TITLE: (args: {downloadCount: number}) => string,
    DOWNLOAD_DESCRIPTION: (args: {freeSpace: string}) => string,
    DOWNLOAD_COUNT_EXCEEDED: (args: {examTitle: string}) => string,

    PREPARING_TITLE: string,
    PREPARING_DESCRIPTION: (args: {freeSpace: string}) => string,

    CONTROL_OVERVIEW: string,
    CONTROL_LANGUAGE: string,
    CONTROL_FINISH: string,
    CONTROL_CALCULATOR: string,
    CONTROL_VERSION: string,
    CONTROL_USERID: string,
    CONTROL_PREVIOUS: string,
    CONTROL_NEXT: string,
    CONTROL_PREVIOUS_CASE: string,
    CONTROL_NEXT_CASE: string,
    CONTROL_PREVIOUS_CANDIDATE: string,
    CONTROL_NEXT_CANDIDATE: string,
    CONTROL_PREVIOUS_STATION: string,
    CONTROL_NEXT_STATION: string,
    CONTROL_COLOUR: string,
    CONTROL_NOTES: string,
    CONTROL_CONNECT: string,
    CONTROL_CONNECTING: string,
    CONTROL_DISCONNECT: string,
    CONTROL_DISCONNECTING: string,
    CONTROL_ID_VALIDATE: (args: {id: string}) => string,
    CONTROL_ID_INVALIDATE: (args: {id: string}) => string,
    CONTROL_MUTE: string,
    CONTROL_UNMUTE: string,
    CONTROL_MICROPHONE: string,
    CONTROL_SPEAKERS: string,
    CONTROL_CAMERA: string,
    CONTROL_HIGHLIGHT: string,

    ID_CHECK_INVALIDATE_CONFIRM: string

    TITLE_ROUND: string,
    TITLE_STATION: string,
    TITLE_CIRCUIT: string,
    TITLE_CASE: (args: {n: number, m: number}) => string,
    TITLE_CONNECT_TO: (args: {factor: string}) => string,

    NOTIFICATION_CONNECT: string,
    NO_CANDIDATE: string,
    REST_STATION: string,

    MEETING_POOR_CONNECTION: string,
    MEETING_STATUS_ERROR: (args: {err: string}) => string,

    TIMER_REMAINING: string,
    TIMER_ELAPSED: string,
    TIMER_ROUND_REMAINING: string,

    OVERVIEW_TITLE: string,
    OVERVIEW_NO_CANDIDATE: string,

    RESOURCE_RESTRICTED_SHOW: string,
    RESOURCE_RESTRICTED_HIDE: string,

    ANSWER_OPTIONAL: string,
    ANSWER_REQUIRED: string,
    ANSWER_TITLE: string,
    ANSWER_FLAG_TIP: string,
    SBA_ANSWER_TIP: string,
    SBA_ELIMINATE_TIP: string,
    NOT_ANSWERED: string,

    PAUSED_TITLE: string,
    PAUSED_DESCRIPTION: string,

    STOPPED_TITLE: string,
    STOPPED_CLEANUP: string,

    MARKSHEET_INVALID: (args: {invalid: string}) => string,

    FINISH_TITLE: string,
    FINISH_DESCRIPTION: string,
    FINISH_ANSWERED: string,
    FINISH_UNANSWERED: (args: {totalUnanswered: number}) => string,
    FINISH_MANDATORY_UNANSWERED: (args: {totalUnanswered: number, mandatoryUnanswered: number}) => string,
    FINISH_SUBMITTED: string,
    FINISH_UNSUBMITTED: (args: {unsubmitted: number}) => string,
    FINISH_CONTINUE: string,
    FINISH_NOW: string,

    INFO_TITLE: string,
    WARN_TITLE: string,
    ALERT_TITLE: string,
    ALERT_OK: string,
    ALERT_CANCEL: string,
    ALERT_RETRY: string,
    ALERT_QUIT: string,
    ALERT_IGNORE: string,

    MEDIA_DEVICES_NOT_SUPPORTED: string,
    CAMERA_ERROR: string,
    CAMERA_INSTRUCTIONS: (args: {err: string}) => string,
    MICROPHONE_ERROR: string,
    MICROPHONE_INSTRUCTIONS: (args: {err: string}) => string,
    NO_CAMERAS: string,
    NO_MICROPHONES: string,
    SCREEN_SHARE_PERMISSION: string,
    SCREEN_SHARE_FAILED: string,

    VIEWER_RESET: string,
    VIEWER_SELECT: string,
    VIEWER_PREV: string,
    VIEWER_NEXT: string,
    VIEWER_FULLSCREEN: string,
    VIEWER_CLOSE: string,
    VIEWER_BROADCAST: string,
    VIEWER_UNDO: string,
    VIEWER_REDO: string,

    LOGOUT_MESSAGE: string,
    LOGIN_AGAIN: string,
    P4B_BACKGROUNDED: string,
    NEWER_VERSION_AVAILABLE: (args: {version: string, latest: string}) => string,
}

const available: string[] = [];
const languages: Array<Language> = [] ; // Array<Partial<Language>> = [];
let currentLanguage = -1;

export async function initTranslation(): Promise<void> {
    if (currentLanguage < 0) {
        try {
            const savedLanguage = await dbGet('session', 'ui-language');
            if (typeof savedLanguage === 'string') {
                currentLanguage = available.indexOf(savedLanguage);
            }
        } catch (err) {
            console.error(String(err));
        }
    }
    if (currentLanguage < 0) {
        currentLanguage = available.indexOf('English');
    }
    if (currentLanguage < 0) {
        currentLanguage = 0;
    }
}

export async function nextLanguage(): Promise<void> {
    if (available.length > 0) {
        currentLanguage++;
        if (currentLanguage >= languages.length) {
            currentLanguage = 0;
        }
        await dbPut('session','ui-language', available[currentLanguage]);
    }
}

export function getLanguages(): string[] {
    console.debug(available);
    return available;
}

export async function setLanguage(language: number) {
    if (language >= 0 && language < languages.length) {
        currentLanguage = language;
        await dbPut('session','ui-language', available[currentLanguage]);
    }
}

export function getCurrentLanguage(): number {
    return currentLanguage;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Args<K extends keyof Language> = Language[K] extends ((_: any) => string) ? Parameters<Language[K]>[0] : never;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Callable<K extends keyof Language> = Language[K] extends ((_: any) => string) ? Language[K] : never;

export function translate<K extends keyof Language>(key: K, args?: Args<K>): string {
    const l = languages[currentLanguage];
    if (l === undefined) {
        return `[${key}]`;
    }
    const t = l[key];
    if (typeof t === 'string') {
        return t;
    } else if (typeof t === 'function' && args !== undefined) {
        return (t as Callable<K>)(args);
    } else {
        return `[${key}]`;
    }
}

export function localise(val: number): string {
    if (currentLanguage) {
        return val.toLocaleString(translate('LOCALE'));
    } else {
        return val.toString();
    }
}

export function registerLanguage(lang: string, dict: Language /*Partial<Language>*/): void {
    console.info('REGISTER_LANGUAGE', lang);
    available.push(lang);
    languages.push(dict);
}


