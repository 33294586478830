import { CanvasRenderingContext2D, createCanvas, ImageData, Image as CanvasImage} from 'canvas';
import { Img, IType, Renderer, registerRendererType } from '@p4b/image-base';
import { Transform } from '@p4b/utils';

//------------------------------------------------------------------------
// Support for standard web image formats

type StdImg = Img;
//export interface StdImg extends Img {}

export function makeStd(id: string, data: ArrayBuffer[]): StdImg {
    return {
        id,
        iType: IType.Std,
        frames: data.map(buf => ({
            data: buf,
            dataSize: buf.byteLength,
        })),
        frameCount: data.length,
    };
}

export function isStdImg(img: Img): img is StdImg {
    return img.iType === IType.Std;
}


export class StdRenderer implements Renderer {
    public index: number;
    public readonly img: StdImg;
    private image: CanvasImage;

    public constructor(img: StdImg) {
        this.index = 0;
        this.img = img;
        this.image = new Image();
    }

    public async init(): Promise<void> {
        // no-op
    }

    public destroy(): void {
        //this.img.data = [];
    }

    private async toImage(data: ArrayBuffer[]): Promise<void>{
        this.image.src = (URL || webkitURL).createObjectURL(new Blob(data));

        await new Promise<void>((succ, fail): void => {
            this.image.onload = () => succ();
            this.image.onerror = () => fail(new Error('Unsupported image format.'));
        });

        this.image.onload = null;
        this.image.onerror = null;

        (URL || webkitURL).revokeObjectURL(this.image.src);

        this.img.cols = this.image.width;
        this.img.rows = this.image.height;
    }

    public async render(): Promise<void>{
        /*if (this.image.width == 0 || this.image.height == 0) {
            return new Promise<void>((succ, fail): void => {
                if (this.img.data[0] == null) {
                    fail('no image data.');
                } else {
                    const blob = new Blob(this.img.data as ArrayBuffer[]); // was [std.data[0]]
                    this.img.data = [];
                    const url = (URL || webkitURL).createObjectURL(blob);

                    this.image.onload = (): void => {
                        (URL || webkitURL).revokeObjectURL(url);
                        //this.img.cols = this.image.width;
                        //this.img.rows = this.image.height;
                        succ();
                    };

                    this.image.onerror = (event: string|Event): void => {
                        (URL || webkitURL).revokeObjectURL(url);
                        console.error(event);
                        fail(event.toString());
                    };

                    this.image.src = url;
                }
            });
        }*/
    }

    public async renderThumbnail(): Promise<ImageData> {
        const frame = this.img.frames[this.index];
        if (!frame.data) {
            throw 'no image data.';
        } else {
            const img = new Image();
            img.src = (URL || webkitURL).createObjectURL(new Blob([frame.data]));
            //this.img.data = [];

            await new Promise<void>((succ, fail): void => {
                img.onload = () => succ();
                img.onerror = () => fail(new Error('unsupported image format.'));
            });

            this.img.cols = img.width;
            this.img.rows = img.height;
            const canvas = createCanvas(120 * this.img.cols / this.img.rows, 120);
            const cxt = canvas.getContext('2d', { alpha: false });
            if (!cxt) {
                throw 'null context';
            }
            cxt.imageSmoothingEnabled = true;
            cxt.imageSmoothingQuality = 'high';
            cxt.drawImage(img, 0, 0, canvas.width, canvas.height);
            (URL || webkitURL).revokeObjectURL(img.src);
            return cxt.getImageData(0, 0, canvas.width, canvas.height);
        }
    }

    public async animationFrame(context: CanvasRenderingContext2D, t: Transform): Promise<void> {
        //context.clearRect(0, 0, context.canvas.width, context.canvas.height);
        context.fillStyle = 'black';
        context.fillRect(0, 0, context.canvas.width, context.canvas.height);
        if (this.image.width && this.image.height) {
            context.setTransform(t.s, t.r, -t.r, t.s, t.tx, t.ty);
            context.imageSmoothingEnabled = true;
            context.imageSmoothingQuality = 'high';
            context.drawImage(this.image, 0, 0);
            //    Math.floor(context.canvas.width / 2 - this.image.width / 2),
            //    Math.floor(context.canvas.height / 2 - this.image.height / 2),
            //);
        }
    }

    public async load(
        resources: {
            getImageBegin(): Promise<void>;
            getImageFrame(start: number, end: number): Promise<ArrayBuffer|undefined>;
            getImageEnd(): Promise<void>;
        },
        render: () => Promise<void>,
        progress: (p: number) => void,
    ): Promise<void> {
        progress(100.0);
        await resources.getImageBegin();
        let j = this.index;
        for (let i = 0; i < this.img.frameCount; ++i) {
            j = (j === this.img.frameCount) ? 0 : j;
            const frame = this.img.frames[j];
            if (frame.dbOffset != undefined) {
                console.debug('STD GET_FRAME');
                frame.data = await resources.getImageFrame(frame.dbOffset, frame.dbOffset + frame.dataSize);
            }
            if (j === this.index && frame.data !== undefined) {
                console.debug('STD TO IMG');
                await this.toImage([frame.data]);
                await render();
            }
            progress(100.0 - 100.0 * (i + 1) / this.img.frameCount);
            ++j;
        }
        await resources.getImageEnd();
    }

    public convexMean(): {mean?: number, stddev?: number} {
        return {};
    }
}

export function isStdRenderer(renderer: Renderer): renderer is StdRenderer {
    return isStdImg(renderer.img);
}

registerRendererType({
    name: 'StdRenderer',
    hasMime(mime: string) {
        return mime === 'image/apng' ||
            mime === 'image/bmp' ||
            mime === 'image/gif' ||
            mime === 'image/x-icon' ||
            mime === 'image/jpeg' ||
            mime === 'image/png' ||
            mime === 'image/svg+xml' ||
            mime === 'image/webp';
    },
    async makeImg(id: string, buffer: ArrayBuffer): Promise<Img> {
        return makeStd(id, [buffer]);
    },
    isThis(resource: Img) {
        return resource.iType === IType.Std;
    },
    makeRenderer(resource: StdImg): Renderer {
        return new StdRenderer(resource);
    }
});