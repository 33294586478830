import { registerLanguage } from '@p4b/utils-lang';

const plural = (x: number) => (x > 1 || x === 0 || x < -1);

registerLanguage('Polski', {
    LOCALE: 'pl-PL',
    LOGIN_INSTRUCTIONS: '<h1>Wprowadź swoje dane logowania</h1>',
    LOGIN_CANDIDATE_ID_LABEL: 'Nazwa użytkownika',
    LOGIN_PASSWORD_LABEL: 'Hasło',
    LOGIN_BUTTON: 'Zaloguj się',
    FREE_SPACE: ({ quota, used }) => `${used}MB zajęte z dostępnych ${quota}MB`,

    CHOOSE_INSTRUCTIONS: ({ cid }) => `<p><b>Jesteś zalogowany jako użytkownik ${cid}</b></p>Jeśli to nie Ty, proszę wyloguj się i zaloguj ponownie, używając podanego identyfikatora użytkownika`,
    CHOOSE_PROCTORED_EXAMITY: `<h1>Proszę kliknąć na \u201cRozpocznij nadzorowanie\u201d, aby uzyskać dostęp do platformy EXAMITY</h1><ul><li>Do utworzenia swojego profilu</li><li>Do połączenia się z osobą nadzorującą i rozpoczęcia egzaminu</li></ul>`,
    CHOOSE_PROCTORED_GENERIC: (proctorName) => `<h1>Proszę kliknąć na \u201cRozpocznij&nbsp;nadzorowanie\u201d, aby uzyskać dostęp do platformy ${proctorName}</h1>`,
    CHOOSE_PROCTORED_SUPPORT: `Jeśli masz trudności z połączeniem się z osobą nadzorującą, proszę skontaktować się z zespołem wsparcia egzaminacyjnego`,
    CHOOSE_NO_EXAMS: `<h1>Nie ma dostępnych egzaminów do zdania</h1>`,
    CHOOSE_SELECT_EXAM: `<h1>Proszę wybrać egzamin, który chcesz rozpocząć</h1>`,
    CHOOSE_AUTO_START: `<h1>Proszę czekać, zostaniesz automatycznie połączony, gdy egzamin zostanie rozpoczęty</h1>`,
    CHOOSE_AUTO_SUPPORT: `Jeśli nie zostaniesz połączony po rozpoczęciu egzaminu, proszę skontaktować się z zespołem wsparcia egzaminacyjnego`,
    CHOOSE_MANUAL_START: `<h1>Wprowadź otrzymany PIN egzaminacyjny</h1>`,
    CHOOSE_MANUAL_SUPPORT: `Jeśli napotkasz trudności przy rozpoczęciu egzaminu, proszę skontaktować się z zespołem wsparcia egzaminacyjnego`,
    CHOOSE_PRESS_START: `<h1>Kliknij \u201cRozpocznij&nbsp;wybrany&nbsp;egzamin\u201d, aby rozpocząć</h1>`,
    CHOOSE_EXAM_LABEL: 'Wybierz egzamin',
    CHOOSE_MANUAL_PIN_LABEL: 'PIN egzaminacyjny',
    CHOOSE_AUTO_PIN_LABEL: 'Offline PIN',
    CHOOSE_EXAM_BUTTON: 'Rozpocznij wybrany egzamin',
    CHOOSE_LOGOUT_BUTTON: 'Wyloguj się',
    CHOOSE_PROCTOR_BUTTON: 'Rozpocznij nadzorowanie',
    CHOOSE_EXAM_HINT: 'Wybierz egzamin...',
    CHOOSE_WAITING_TO_START: 'Egzaminy oczekujące na rozpoczęcie',
    CHOOSE_ALREADY_STARTED: 'Egzaminy już rozpoczęte',
    CHOOSE_OFFLINE_BUTTON: 'Tryb offline',

    ERROR_NETWORK: 'Błąd sieciowy',
    ERROR_USER_PASS: '<b>Autoryzacja nieudana. Nie ma takiego konta lub podałeś nieprawidłowe dane logowania.</b>',
    ERROR_CONNECTION_TIMEOUT: 'Przekroczony limit czasu połączenia',
    ERROR_HTTP: ({ code }) => `Błąd serwera ${code ? ` (HTTP ${code})` : ''}`,
    ERROR_PROXY: 'Serwer zajęty, spróbuj ponownie.',
    ERROR_UNKNOWN: ({ message }) => `Błąd ${message ? ` [${message}]` : ''}`,
    ERROR_STORAGE: "Brak miejsca na dysku. Zwolnij miejsce lub użyj innego urządzenia.",
    ERROR_PIN: '<b>Nieprawidłowy PIN lub wybrany zły egzamin<b>',
    ERROR_CANDIDATE_NOT_FOUND: 'Błędny identyfikator użytkownika lub użytkownik nie jest zarejestrowany na ten egzamin.',
    ERROR_RESEND: ({ err }) => `<b>${err}</b><p>Nie można skontaktować się z serwerem. Możesz kontynuować egzamin offline, odpowiedzi zostaną zapisane lokalnie na tym urządzeniu, ale muszą zostać przesłane na serwer przed zakończeniem egzaminu. Nie będziesz mógł zobaczyć żadnych odpowiedzi wcześniej przesłanych z innego urządzenia. Jeśli połączenie zostanie przywrócone, wszystkie odpowiedzi zostaną automatycznie zapisane na serwerze.</p>`,
    ERROR_DOWNLOAD: 'Nie można pobrać egzaminu',

    DOWNLOAD_TITLE: ({ downloadCount }) => `Pobieranie ${downloadCount} egzaminu${plural(downloadCount) ? 'ów' : ''}, proszę czekać...`,
    DOWNLOAD_DESCRIPTION: ({ freeSpace }) => `<p>Pobieranie może zająć kilka minut w zależności od szybkości łącza internetowego</p>${freeSpace}`,
    DOWNLOAD_COUNT_EXCEEDED: ({ examTitle }) => `Przekroczono limit pobierań: ${examTitle}`,

    PREPARING_TITLE: 'Przygotowanie egzaminu, proszę czekać...',
    PREPARING_DESCRIPTION: ({freeSpace}) => `<p>Przygotowanie może zająć kilka minut w zależności od specyfikacji Twojego komputera<p>${freeSpace}`,

    CONTROL_OVERVIEW: 'PRZEGLĄD',
    CONTROL_LANGUAGE: 'JĘZYK',
    CONTROL_FINISH: 'ZAKOŃCZ',
    CONTROL_CALCULATOR: 'KALKULATOR',
    CONTROL_COLOUR: 'KOLOR',
    CONTROL_VERSION: 'WERSJA',
    CONTROL_USERID: 'ID UŻYTKOWNIKA',
    CONTROL_PREVIOUS: 'POPRZEDNI',
    CONTROL_NEXT: 'NASTĘPNY',
    CONTROL_PREVIOUS_CASE: 'POPRZEDNI PRZYPADEK',
    CONTROL_NEXT_CASE: 'NASTĘPNY PRZYPADEK',
    CONTROL_PREVIOUS_CANDIDATE: 'POPRZEDNI KANDYDAT',
    CONTROL_NEXT_CANDIDATE: 'NASTĘPNY KANDYDAT',
    CONTROL_PREVIOUS_STATION: 'POPRZEDNIA STACJA',
    CONTROL_NEXT_STATION: 'NASTĘPNA STACJA',
    CONTROL_NOTES: 'NOTATKI',
    CONTROL_CONNECT: 'POŁĄCZ',
    CONTROL_CONNECTING: 'ŁĄCZENIE',
    CONTROL_DISCONNECT: 'ROZŁĄCZ',
    CONTROL_DISCONNECTING: 'ROZŁĄCZANIE',
    CONTROL_ID_VALIDATE: ({id}) => `WERYFIKUJ ${id}`,
    CONTROL_ID_INVALIDATE: ({id}) => `NIEPRAWIDŁOWE ${id}`,
    CONTROL_MUTE: 'WYŁĄCZ DŹWIĘK',
    CONTROL_UNMUTE: 'WŁĄCZ DŹWIĘK',
    CONTROL_CAMERA: 'KAMERA',
    CONTROL_SPEAKERS: 'GŁOŚNIKI',
    CONTROL_MICROPHONE: 'MIKROFON',
    CONTROL_HIGHLIGHT: '(ODZNACZ)/(OZNACZ)',

    ID_CHECK_INVALIDATE_CONFIRM:  'Czy na pewno chcesz unieważnić to ID?',

    TITLE_ROUND: 'tura',
    TITLE_STATION: 'stacja',
    TITLE_CIRCUIT: 'układ',
    TITLE_CASE: ({n, m}) => `przypadek&nbsp;<b>${n}</b>&nbsp;z&nbsp;<b>${m}</b>`,
    TITLE_CONNECT_TO: ({factor}) => `połącz&nbsp;z&nbsp;${factor}`,

    NOTIFICATION_CONNECT: '<center>Kliknij przycisk <b>POŁĄCZ</b> powyżej, aby rozpocząć.</center>',
    NO_CANDIDATE: 'Brak kandydata na tę turę.',
    REST_STATION: 'To jest stacja odpoczynku.',

    MEETING_POOR_CONNECTION: 'Słabe połączenie: mogą wystąpić problemy z jakością dźwięku i obrazu.',
    MEETING_STATUS_ERROR: ({err}) => `Status zakończenia spotkania: ${err}`,

    TIMER_REMAINING: 'POZOSTAŁY CZAS',
    TIMER_ELAPSED: 'UPŁYNĘŁY CZAS',
    TIMER_ROUND_REMAINING: 'POZOSTAŁY CZAS TURY',

    OVERVIEW_TITLE: 'Przegląd',
    OVERVIEW_NO_CANDIDATE: 'Brak kandydata',
    RESOURCE_RESTRICTED_SHOW: 'Pokaż',
    RESOURCE_RESTRICTED_HIDE: 'Ukryj',

    ANSWER_OPTIONAL: 'opcjonalne',
    ANSWER_REQUIRED: 'wymagane',
    ANSWER_TITLE: 'Odpowiedź',
    ANSWER_FLAG_TIP: 'Oznacz to pytanie',
    SBA_ANSWER_TIP: 'Wybierz tę odpowiedź',
    SBA_ELIMINATE_TIP: 'Eliminuj tę odpowiedź',
    NOT_ANSWERED: 'Brak odpowiedzi',

    PAUSED_TITLE: 'Egzamin wstrzymany',
    PAUSED_DESCRIPTION: '<p>Egzamin został <strong>wstrzymany</strong> zdalnie przez administratora.<p>',

    STOPPED_TITLE: 'Zakończony egzamin',
    STOPPED_CLEANUP: '<p>Czyszczenie, proszę czekać...<p>',

    MARKSHEET_INVALID: ({invalid}) => `Poniższe kryteria obowiązkowe wymagają odpowiedzi:<br><br><b>${invalid}</b><br><br><div style="text-align:left"><b>OK</b> - aby opuścić ten arkusz ocen.<br><b>ANULUJ</b> - aby pozostać na tym arkuszu ocen i zobaczyć, które kryteria są brakujące.</div>`,

    FINISH_TITLE: 'Gotowy do zakończenia?',
    FINISH_DESCRIPTION: 'Kliknij \u201cPrześlij egzamin\u201d przed zamknięciem karty lub okna',
    FINISH_ANSWERED: 'Odpowiedziałeś na wszystkie pytania',
    FINISH_UNANSWERED: ({totalUnanswered}) => `Masz ${totalUnanswered} nieodpowiedziane pytanie${plural(totalUnanswered) ? 'a' : ''}`,
    FINISH_MANDATORY_UNANSWERED: ({totalUnanswered, mandatoryUnanswered}) => `Masz ${totalUnanswered} nieodpowiedziane pytanie${plural(totalUnanswered) ? 'a' : ''} (${mandatoryUnanswered} obowiązkowych)`,
    FINISH_SUBMITTED: '<p><b>Wszystkie Twoje odpowiedzi zostały przesłane na serwer.</b></p>',
    FINISH_UNSUBMITTED: ({unsubmitted}) => `<p>Masz <strong>${unsubmitted}</strong> odpowiedzi${plural(unsubmitted) ? 'e' : ''}, które ${plural(unsubmitted) ? 'są' : 'jest'} nieprzesłane na serwer.</p><p>Możesz kontynuować egzamin, ponieważ odpowiedzi zostaną zapisane na Twoim urządzeniu.</p>`,
    FINISH_CONTINUE: 'Kontynuuj egzamin',
    FINISH_NOW: 'Prześlij egzamin',

    INFO_TITLE: 'Instrukcje',
    WARN_TITLE: 'Ostrzeżenie',
    ALERT_TITLE: 'Coś poszło nie tak',
    ALERT_OK: 'OK',
    ALERT_CANCEL: 'ANULUJ',
    ALERT_RETRY: 'PONÓW',
    ALERT_QUIT: 'ZAKOŃCZ',
    ALERT_IGNORE: "ANULUJ",

    MEDIA_DEVICES_NOT_SUPPORTED: 'Ta przeglądarka nie obsługuje kamer i mikrofonów',
    CAMERA_ERROR: 'Błąd dostępu do kamery',
    CAMERA_INSTRUCTIONS: ({err}) => `<p>[${err}]</p><ul><li>Sprawdź, czy Twój sprzęt ma wbudowaną kamerę lub czy masz podłączoną kamerę</li><li>Może być konieczne włączenie dostępu do kamery dla <b>risr/</b>assess w ustawieniach prywatności i zabezpieczeń przeglądarki</li><li>Może być konieczne włączenie dostępu do kamery dla przeglądarki w ustawieniach prywatności i zabezpieczeń Twojego urządzenia</li></ul>`,
    MICROPHONE_ERROR: "Błąd dostępu do mikrofonu",
    MICROPHONE_INSTRUCTIONS: ({err}) => `<p>[${err}]</p><ul><li>Sprawdź, czy Twój sprzęt ma wbudowany mikrofon lub czy masz podłączony mikrofon</li><li>Może być konieczne włączenie dostępu do mikrofonu dla <b>risr/</b>assess w ustawieniach prywatności i zabezpieczeń przeglądarki</li><li>Może być konieczne włączenie dostępu do mikrofonu dla przeglądarki w ustawieniach prywatności i zabezpieczeń Twojego urządzenia</li></ul>`,
    NO_CAMERAS: 'Nie znaleziono kamer',
    NO_MICROPHONES: 'Nie znaleziono mikrofonów',
    SCREEN_SHARE_PERMISSION: '<p>W tej sesji egzaminacyjnej musisz <i>zezwolić</i> na udostępnienie swojego ekranu egzaminatorowi.</p><ul><li>Musisz udostępnić cały ekran, więc jeśli masz opcje, powinieneś wybrać "cały ekran", "cały ekran" lub odpowiednik.</li><li>Może być konieczne zezwolenie na "udostępnianie ekranu" lub "nagrywanie ekranu" dla <b>risr/</b>assess w ustawieniach prywatności i zabezpieczeń przeglądarki</li><li>Może być konieczne zezwolenie na "udostępnianie ekranu" lub "nagrywanie ekranu" dla przeglądarki w ustawieniach prywatności i zabezpieczeń Twojego urządzenia</li></ul',
    SCREEN_SHARE_FAILED: '<b>Udostępnianie ekranu jest obecnie <i>zablokowane</i>.</b>',

    VIEWER_RESET: 'RESETUJ WIDOK',
    VIEWER_SELECT: 'WYBIERZ AKCJĘ KLIKNIĘCIA',
    VIEWER_PREV: 'POPRZEDNIA STRONA',
    VIEWER_NEXT: 'NASTĘPNA STRONA',
    VIEWER_FULLSCREEN: 'PRZEŁĄCZ PEŁNY EKRAN',
    VIEWER_CLOSE: 'ZAMKNIJ WIDOK',
    VIEWER_BROADCAST: 'PRZEŚLIJ ZMIANY',
    VIEWER_UNDO: 'COFNIJ ZMIANY',
    VIEWER_REDO: 'PRZYWRÓĆ ZMIANY',

    LOGOUT_MESSAGE: '<p>Jesteś obecnie wylogowany, kliknij poniżej, aby zalogować się ponownie.</p>',
    LOGIN_AGAIN: 'ZALOGUJ SIĘ',
    P4B_BACKGROUNDED: 'Ta karta została zawieszona, ponieważ inna karta egzaminu <b>risr/</b>assess była aktywna. Kliknij OK, aby wznowić.',
    NEWER_VERSION_AVAILABLE: ({version, latest}) => `<p>Dostępna jest nowsza wersja <b>risr/</b>assess</p><p>zainstalowana: ${version}<br/>dostępna: ${latest}</p>`,
});